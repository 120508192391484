<template>
    <div>
        <div v-if="blocks && blocks.length == 0">
            <v-btn block x-large @click="addAt(0)">Add a Content Block!</v-btn>
        </div>
        <draggable v-else tag="div" :list="blocks" class="list-group mt-6" handle=".handle">
            <v-card class="list-group-item block mb-12 mt-10 mx-5" outlined v-for="(element, idx) in blocks" :key="element.key">
                <div column :class="collapsedClasses(['fab-container'])">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" small fab v-on="on">
                                <v-icon small>fa fa-plus</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item @click="addAt(idx)">
                                <v-list-item-title>Add Block Above</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="addAt(idx + 1)">
                                <v-list-item-title>Add Block Below</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="copyAt(idx)">
                                <v-list-item-title>Copy Block</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="pasteAt(idx)">
                                <v-list-item-title>Paste Block Above</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="pasteAt(idx + 1)">
                                <v-list-item-title>Paste Block Below</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="removeAt(idx)">
                                <v-list-item-title>Cut/Remove Block</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" v-on="on" height="40px">
                                <v-icon small left>fa fa-cubes</v-icon>{{ getBlockTypeLabel(element) }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="changeBlockType(idx, type.value)" v-for="(type, key) in available_types" :key="key">
                                <v-list-item-title>{{ type.label }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" small fab v-on="on">
                                <v-icon small>fa fa-paint-brush</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-row color="white">
                                    <v-col cols="6" md="3" v-for="(style, key) in available_styles" :key="key">
                                        <v-checkbox v-model="blocks[idx].styles" :label="style" :value="style"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" small fab v-on="on" @click="toggleBlockVisible(idx)">
                                <v-icon small :class="publishStatusClass(element.status)">{{ element.status === 'draft' ? 'fa fa-eye-slash' : 'fa fa-eye' }}</v-icon>
                            </v-btn>
                        </template>
                    </v-menu>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" small fab v-on="on">
                                <v-icon small>fa fa-calendar-alt</v-icon>
                                <span v-html="publishingDates(element)"></span>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item>
                                <date-selector v-model="element.startDate" label="Start Date"></date-selector>
                            </v-list-item>
                            <v-list-item>
                                <date-selector v-model="element.endDate" label="End Date"></date-selector>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                            <v-btn class="handle mx-1" outlined color="gray" small fab v-on="on">
                                <v-icon small>fa fa-filter</v-icon>
                            </v-btn>
                            <!-- <span v-if="blockIsHidden(blocks[idx])">Hidden for {{user.currentSetDivision}}</span> -->
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-row color="white">
                                    <v-col cols=12 class="pb-0">
                                        <v-radio-group v-model="blocks[idx].permissions.exclude" row dense>
                                            <v-radio label="Include" value="include"></v-radio>
                                            <v-radio label="Exclude" value="exclude"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols=4>
                                        <v-select multiple :items="division_items" v-model="blocks[idx].permissions.division" label="Division"></v-select>
                                    </v-col>
                                    <v-col cols=4>
                                        <v-select multiple :items="role_items" v-model="blocks[idx].permissions.role" label="Roles"></v-select>
                                    </v-col>
                                    <v-col cols=4>
                                        <v-select multiple :items="permission_items" v-model="blocks[idx].permissions.permission" label="Permissions"></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <v-btn class="handle " dark color="gray" small fab absolute right>
                        <v-icon small>fas fa-arrows-alt</v-icon>
                    </v-btn>
                </div>
                <div :class="blockClasses(blocks[idx], 'pa-2 mb-6 block-container')">
                    <div :is="element.type + '-editor'" v-model="blocks[idx]"></div>
                </div>
            </v-card>
        </draggable>
        <div class="text-center mt-4" v-if="blocks && blocks.length > 0">
            <v-btn x-large @click="addAt(blocks.length)">
                <v-icon left small>fas fa-plus</v-icon>New Content Block
            </v-btn>
        </div>
    </div>
</template>
<script>
import draggable from "vuedraggable";
import AccordionEditor from './components/editors/AccordionEditor.vue'
import SpacerEditor from './components/editors/SpacerEditor.vue'
import FullWidthTextEditor from './components/editors/FullWidthTextEditor.vue'
import GlossaryEditor from "./components/editors/GlossaryEditor.vue";
import ZendeskTextEditor from './components/editors/ZendeskTextEditor.vue'
import TwoCol5050Editor from './components/editors/TwoCol5050Editor.vue'
import ButtonGroupEditor from './components/editors/ButtonGroupEditor.vue'
import CardGroupEditor from './components/editors/CardGroupEditor.vue'
import WistiaVideoGroupEditor from './components/editors/WistiaVideoGroupEditor.vue'
import IframeGroupEditor from './components/editors/IframeGroupEditor.vue'
import BannerEditor from './components/editors/BannerEditor.vue'
import LeaderboardEditor from './components/editors/LeaderboardEditor.vue'
import ScriptEmbedGroupEditor from './components/editors/ScriptEmbedGroupEditor.vue'
import TwoCol7525Editor from './components/editors/TwoCol7525Editor.vue'
import TwoCol2575Editor from './components/editors/TwoCol2575Editor.vue'
import CallToActionEditor from './components/editors/CallToActionEditor.vue'
import FourCol25252525Editor from './components/editors/FourCol25252525Editor.vue'
import ThreeCol333333Editor from './components/editors/ThreeCol333333Editor.vue'
import ThreeCol255025Editor from './components/editors/ThreeCol255025Editor.vue'
import CarrierContactInfoEditor from './components/editors/CarrierContactInfoEditor.vue'
import CarrierProductsEditor from './components/editors/CarrierProductsEditor.vue'
import AcknowledgeCheckBoxEditor from './components/editors/AcknowledgeCheckBoxEditor.vue'
import DataTableGroupEditor from './components/editors/DataTableGroupEditor.vue'
import QFormEditor from './components/editors/FormEditor.vue'
import { EventBus } from '@/event-bus';
import Styles from './components/Styles'
import ImageSelector from './components/ImageSelector.vue'
import DateSelector from './components/DateSelector.vue'
import SummitCalculatorEditor from "@/CMS/editor/components/editors/SummitCalculatorEditor";

import moment from 'moment'
export default {
    name: "QEditPageBlocks",
    props: ['value', 'random'],
    data() {
        return {
            mediaCallback: function() {},
            show_media: false, //shows the media library
            show_documents: false, // shows the document library
            dragging: false,
            counter: Math.floor(Math.random() * 1000000000),
            page_id: null,
            hasChanges: false,
            original_status: null,
            currentTab: 0,
            available_types: [{
                label: "Accordion",
                value: "accordion"
            }, {
                label: "Banner",
                value: "banner"
            }, {
                label: "Leaderboard",
                value: "leaderboard"
            }, {
                label: "Data Table",
                value: "data-table-group"
            }, {
                label: "Full Width Text Block",
                value: "full-width-text"
            }, {
                label: "Glossary",
                value: "glossary"
            }, {
                label: "Two Columns 50/50",
                value: "two-col-5050"
            }, {
                label: "Two Columns 25/75",
                value: "two-col-2575"
            }, {
                label: "Two Columns 75/25",
                value: "two-col-7525"
            }, {
                label: "Three Columns 33/33/33",
                value: "three-col-333333"
            }, {
                label: "Three Columns 25/50/25",
                value: "three-col-255025"
            }, {
                label: "Four Columns 25/25/25/25",
                value: "four-col-25252525"
            }, {
                label: "Card Group",
                value: "card-group"
            }, {
                label: "Wistia Video Group",
                value: "wistia-video-group"
            }, {
                label: "Iframe Group",
                value: "iframe-group"
            }, {
                label: "Script/Embed Group",
                value: "script-embed-group"
            }, {
                label: "Button Group",
                value: "button-group"
            }, {
                label: "Call to Action",
                value: "call-to-action"
            }, {
                label: "Spacer",
                value: "spacer"
            }, {
                label: "Carrier Contact Info",
                value: "carrier-contact-info"
            }, {
                label: "Carrier Products",
                value: "carrier-products"
            }, {
                label: "Acknowledge Checkbox",
                value: "acknowledge-check-box"
            }, {
                label: 'Summit Calculator',
                value: 'summit-calculator',
            }, {
                label: 'Q Form',
                value: 'q-form',
            }],
            available_templates: [{
                text: 'Default Template',
                value: 'default'
            }, {
                text: 'Resources',
                value: 'resources'
            }, {
                text: 'Training',
                value: 'training'
            }, {
                text: 'News',
                value: 'news'
            }, {
                text: 'Contest',
                value: 'contests'
            }, {
                text: 'Events',
                value: 'events'
            }, {
                text: 'Marketing',
                value: 'marketing'
            }, {
                text: 'Agency Building',
                value: 'agency-building'
            }, {
                text: 'External Resource',
                value: 'external'
            }, {
                text: 'Carrier',
                value: 'carrier'
            }, ],
            available_styles: Styles.BlockStyles,
            available_page_styles: Styles.PageStyles,
            collapseBlocks: false,
            showDialogConfirmDelete: false,
            carriers: [],
            carrier: {},
            CarrierLogoURL: false,
            loadingCarrier: false
        };
    },
    mounted: function() {
        var g = this
        EventBus.$on('medialibrary', function(callback) {
            g.show_media = true;
            g.mediaCallback = callback;
        });
        EventBus.$on('documentlibrary', function(callback) {
            g.show_documents = true;
            g.documentCallback = callback;
        });
    },
    beforeActivated() {

    },
    computed: {
        division_items: function() {
            return [
                'All',
                ...this.raw_divisions,
            ]
        },
        role_items: function() {
            return [
                'Any',
                ...this.role_choices,
            ]
        },
        permission_items: function() {
            return [
                'Any',
                ...this.permission_choices,
            ]
        },
        blocks: function() {
            return this.value
        }
    },
    methods: {
        removeAt(idx) {
            var block = this.blocks[idx]
            localStorage.setItem('copiedBlock', JSON.stringify(block))
            this.blocks.splice(idx, 1);
        },
        addAt(idx) {
            this.counter++;
            this.blocks.splice(idx, 0, {
                id: this.counter,
                type: 'full-width-text',
                text: 'Sed nec diam non eros lobortis tempor et eget sapien. Sed rutrum orci eget ligula mattis, vel vestibulum ligula feugiat. Cras et velit ut ipsum fringilla vulputate.',
                hasFocus: false,
                styles: [],
                permissions: { role: null, permission: null, division: null },
                CarrierID: this.carrier_id,
                CarrierIDs: [this.carrier_id],
                key: Math.random()
            });
        },
        copyAt(idx) {
            var block = this.blocks[idx]
            localStorage.setItem('copiedBlock', JSON.stringify(block))
        },
        pasteAt(idx) {
            if (localStorage.getItem('copiedBlock') == null)
                return
            var block = JSON.parse(localStorage.getItem('copiedBlock'))
            this.counter++;
            block.id = this.counter
            block.key = Math.random()
            this.blocks.splice(idx, 0, block)

        },
        setFocus: function(idx) {
            for (var i = 0; i < this.blocks.length; i++) {
                if (idx != i) {
                    this.blocks.hasFocus = false;
                }
            }
        },
        changeBlockType: function(idx, type) {
            // console.log("changeBlockType", this.blocks)
            this.blocks[idx].type = type;
            if (typeof this.blocks[idx].styles == 'undefined') {
                this.blocks[idx].styles = [];
            }
        },
        useMediaFile: function(file) {
            this.mediaCallback(file);
            this.show_media = false;
        },
        useDocumentFile: function(file) {
            this.documentCallback(file.url, file.title);
            this.show_documents = false;
        },
        collapsedClasses: function(classes) {
            if (this.collapseBlocks)
                classes.push('collapsed')
            return classes.join(' ')
        },
        collapseBlocksIcon: function() {
            if (this.collapseBlocks)
                return "fa-chevron-left"
            return "fa-chevron-down"
        },
        getBlockTypeLabel(block) {
            return this.titleCase(block.type.replace('-', ' '));
        },
        formatLabel: function(key) {
            var str = key.replace("_", " ");
            str = str.replace("-", " ");
            return this.titleCase(str);
        },
        toggleBlockVisible: function(idx) {
            this.blocks[idx].status = this.blocks[idx].status !== 'draft' ? 'draft' : 'publish'
        },
        publishingDates: function(element) {
            var dates = ""

            if (element.startDate !== undefined && element.startDate !== "" && element.startDate !== null)
                dates += moment(element.startDate).format("l")

            dates += " - "

            if (element.endDate !== undefined && element.endDate !== "" && element.endDate !== null)
                dates += moment(element.endDate).format("l")

            if (dates !== " - ")
                return '<span class="ml-2">' + dates + '</span>'

            return ''
        },
        publishStatusClass(status) {
            return status === 'draft' ? 'unpublished-block' : ''
        },
        toggleCollapseBlocks: function() {
            this.collapseBlocks = !this.collapseBlocks
        },
        blockIsHidden(block) {
            if (!block || !block.permissions)
                return false
            if (typeof block.permissions.division == 'undefined') {
                return false
            }
            if (block.permissions.division == 'All' || block.permissions.division == null) {
                return false
            }
            if (block.permissions.division == this.user.currentSetDivision) {
                return false
            }
            return true
        }
    },
    watch: {
        // 'page.blocks': {
        //     deep: true,
        //     handler: function(newV, oldV) {
        //         this.hasChanges = true;
        //     }
        // },
    },
    components: {
        draggable,
        AccordionEditor,
        FullWidthTextEditor,
        SpacerEditor,
        TwoCol5050Editor,
        ButtonGroupEditor,
        CardGroupEditor,
        IframeGroupEditor,
        CallToActionEditor,
        FourCol25252525Editor,
        ThreeCol333333Editor,
        ThreeCol255025Editor,
        TwoCol2575Editor,
        TwoCol7525Editor,
        BannerEditor,
        LeaderboardEditor,
        ZendeskTextEditor,
        ImageSelector,
        DateSelector,
        ScriptEmbedGroupEditor,
        CarrierContactInfoEditor,
        CarrierProductsEditor,
        AcknowledgeCheckBoxEditor,
        WistiaVideoGroupEditor,
        DataTableGroupEditor,
        SummitCalculatorEditor,
        QFormEditor,
        GlossaryEditor,
    },
}
</script>
<style scoped>
.block {
    position: relative;
}

.fab-container {
    padding: 15px;
    background-color: #f7f7f7;
    padding-right: 60px;
}

.unpublished-block {
    color: rgba(0, 0, 0, 0.1) !important;
}

.fab-container.collapsed {
    position: relative;
}

.block-container.collapsed {
    display: none;
}
</style>