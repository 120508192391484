<template>
    <q-submenu :base-url="baseUrl" :title="title" :titlelink="titlelink" :buttons="buttons" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "Carriers & Products",
            titlelink: null,
            baseUrl: '/admin',
        }
    },
    computed: {
        'buttons': function() {
            var b = [{
                label: "Carrier Resources",
                to: "/page/available-carriers",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Product Concepts",
                to: "/training/product-concepts",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "E-Apps",
                to: "/page/carriers-e-app-platforms",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "QuilityRx",
                to: "/qms/quility-rx",
                icon: "fas fa-arrow-alt-circle-right",
            }]
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff', 'AgencyOwner'])) {
                b.push({
                    label: "Master Commission Schedule",
                    href: "https://quility-my.sharepoint.com/:x:/p/logan_long/ESsVzANNzrxOraSOin4hwwoBzocaV6mlTbBY2b8vceUu5g?e=58TbvO",
                    icon: "fas fa-external-link-alt",
                });
            }
            return b;
        }
    },
    components: {
        QSubmenu
    },
    methods: {},
}
</script>
<style>
</style>