import { getInstance } from "@/auth/index";
export default {

    loadTop5Leadersboards(timeframe, filters, options) {
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/leaderboards/' + timeframe.toLowerCase(), params)
            .then(function(json) {
                return json
            }).catch(function(err) {
                return err
            })
    },

    loadLeaderboard(timeframe, type, agentCode, rows, showAll, filters, options) {
        var queuKey = "getLeaderboard" + type;
        this.cancelQRequest(queuKey);
        var params = {
            ...options,
            ...filters
        }
        if (typeof timeframe == 'undefined') {
            timeframe = 'weekly'
        }
        if (typeof type == 'undefined') {
            type = 'direct'
        }
        if (typeof agentCode == 'undefined') {
            agentCode = ''
        }
        params.numPerPage = rows;
        params.showAll = showAll;
        return this.getRequest('/api/private/leaderboards/' + timeframe.toLowerCase() + '/' + type.toLowerCase() + '/' + agentCode, params, queuKey)
            .then(function(json) {
                return json
            }).catch(function(err) {
                return err
            })
    },

    loadEventLeaderboard(agentcode, type, filters, options) {
        var queuKey = "loadEventLeaderboard" + type
        this.cancelQRequest(queuKey)
        if(!options){
            options = {
                'type': type
            }
        }
        else{
            options['type'] = type
        }

        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/leaderboards/events/' + agentcode, params, queuKey)
        .then(function(json) {
            return json
        }).catch(function(err) {
            return err
        })
    },
    loadRxLeadersboards(timeframe, filters, options) {
        var params = {
            ...options,
            ...filters,
            timeframe
        }
        return this.getRequest('/api/private/leaderboards/rx', params)
            .then(function(json) {
                return json
            }).catch(function(err) {
                return err
            })
    },
}
