<template>
    <div>
        <div v-if="the_form && noTemplate === true">
            <div v-if="hasPermission(['manage:Forms'])">
                <v-btn fab absolute right center class="fml-edit-btn" :to="{name : 'FMLEditor', 'params' : {form_id : the_form.ID} }">
                    <v-icon small class="mr-2">fas fa-pencil-alt</v-icon>
                </v-btn>
            </div>
            <div v-if="show_response">
                <div v-html="the_form.FormConfig.response"></div>
                <v-btn @click="resetForm">Reset Form</v-btn>
            </div>
            <div v-else>
                <h2>{{the_form.FormTitle}}</h2>
                <p v-if="the_form.FormContent">{{the_form.FormContent}}</p>
                <div v-for="(set, key) in the_form.FormQuestionSets" :key="'question_set_' + set.ID" :class="blockClasses(set.QuestionSetConfig, 'my-0')">
                    <fml-question-set :disable-field="disable_field" :question-set="set" v-model="the_form.FormResponse" :last-set="key == the_form.FormQuestionSets.length - 1" v-on:submitted="showResponseText"></fml-question-set>
                </div>
            </div>
        </div>
        <div v-else-if="the_form && is_visible" :is="the_form.FormConfig.template" :content-data="the_form">
            <slot></slot>
            <div v-if="hasPermission(['manage:Forms'])">
                <v-btn fab absolute right center class="fml-edit-btn" :to="{name : 'FMLEditor', 'params' : {form_id : the_form.ID} }">
                    <v-icon small class="mr-2">fas fa-pencil-alt</v-icon>
                </v-btn>
            </div>
            <div v-if="show_response">
                <div v-html="the_form.FormConfig.response"></div>
                <v-btn @click="resetForm">Reset Form</v-btn>
            </div>
            <div v-else>
                <h2>{{the_form.FormTitle}}</h2>
                <p v-if="the_form.FormContent">{{the_form.FormContent}}</p>
                <div v-for="(set, key) in the_form.FormQuestionSets" :key="'question_set_' + set.ID" :class="blockClasses(set.QuestionSetConfig, 'my-0')">
                    <fml-question-set :disable-field="disable_field" :question-set="set" v-model="the_form.FormResponse" :last-set="key == the_form.FormQuestionSets.length - 1" v-on:submitted="showResponseText"></fml-question-set>
                </div>
            </div>
        </div>
        <v-progress-linear v-if="!the_form" indeterminate color="primary"></v-progress-linear>
        <div v-if="the_form && !is_visible">
            <h1 class="mt-6 ml-6">Page not found</h1>
        </div>
        <v-expansion-panels v-if="hasRole(['SuperAdmin', 'Staff'])" class="mt-8">
            <v-expansion-panel v-if="the_form">
                <v-expansion-panel-header>Debug</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <pre v-if="the_form.FormResponse">{{ JSON.stringify(the_form.FormResponse, null, 2) }}</pre>
                    <hr>
                    <pre>{{ JSON.stringify(the_form, null, 2) }}</pre>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
import Default from '@/CMS/views/PageTemplates/default.vue';
import Marketing from '@/CMS/views/PageTemplates/marketing.vue';
import QuilityTech from '@/CMS/views/PageTemplates/quility-tech.vue';
import Resources from '@/CMS/views/PageTemplates/resources.vue';
import Training from '@/CMS/views/PageTemplates/training.vue';
import News from '@/CMS/views/PageTemplates/news.vue';
import Contests from '@/CMS/views/PageTemplates/contests.vue';
import Events from '@/CMS/views/PageTemplates/events.vue';
import Benefits from '@/CMS/views/PageTemplates/benefits.vue';
import Zendesk from '@/CMS/views/PageTemplates/zendesk.vue';
import AgencyBuilding from '@/CMS/views/PageTemplates/agency-building.vue';
import External from '@/CMS/views/PageTemplates/external.vue';
import Carrier from '@/CMS/views/PageTemplates/carrier.vue';
import CarrierResources from '@/CMS/views/PageTemplates/carrier-resources.vue';
import { EventBus } from '@/event-bus';

import FmlQuestionSet from './FmlQuestionSet.vue';
/*
import Spacer from '@/CMS/views/ContentBlocks/Spacer.vue';
import CallToAction from '@/CMS/views/ContentBlocks/CallToAction.vue';
import FullWidthText from '@/CMS/views/ContentBlocks/FullWidthText.vue';
import ZendeskText from '@/CMS/views/ContentBlocks/ZendeskText.vue';
import TwoCol5050 from '@/CMS/views/ContentBlocks/TwoCol5050.vue';
import TwoCol2575 from '@/CMS/views/ContentBlocks/TwoCol2575.vue';
import TwoCol7525 from '@/CMS/views/ContentBlocks/TwoCol7525.vue';
import ThreeCol255025 from '@/CMS/views/ContentBlocks/ThreeCol255025.vue';
import ThreeCol333333 from '@/CMS/views/ContentBlocks/ThreeCol333333.vue';
import FourCol25252525 from '@/CMS/views/ContentBlocks/FourCol25252525.vue';
import CardStyles from '@/CMS/views/ContentBlocks/CardStyles.vue';
import ButtonGroup from '@/CMS/views/ContentBlocks/ButtonGroup.vue';
import CardGroup from '@/CMS/views/ContentBlocks/CardGroup.vue';
import WistiaVideoGroup from '@/CMS/views/ContentBlocks/WistiaVideoGroup.vue';
import IframeGroup from '@/CMS/views/ContentBlocks/IframeGroup.vue';
import Banner from '@/CMS/views/ContentBlocks/Banner.vue';
import ScriptEmbedGroup from '@/CMS/views/ContentBlocks/ScriptEmbedGroup.vue';
import CarrierContactInfo from '@/CMS/views/ContentBlocks/CarrierContactInfo.vue';
import CarrierProducts from '@/CMS/views/ContentBlocks/CarrierProducts.vue';
import CarrierLogo from '@/CMS/views/ContentBlocks/CarrierLogo.vue';
import AcknowledgeCheckBox from '@/CMS/views/ContentBlocks/AcknowledgeCheckBox.vue';
import Accordion from '@/CMS/views/ContentBlocks/Accordion.vue';
import DataTableGroup from '@/CMS/views/ContentBlocks/DataTableGroup.vue';
import SummitCalculator from "@/CMS/views/ContentBlocks/SummitCalculator";
*/

import QuilityAPI from '@/store/API/QuilityAPI.js'
//import Page from '@/store/Models/Page'
import moment from 'moment'

export default {
    props: ['form', 'slug', 'noTemplate'],
    data: () => ({
        loaded: false,
        the_form: null,
        show_response: false,
        reload_counter: 0,
    }),
    mounted() {
        if (typeof this.form != 'undefined') {
            let f = Object.assign({}, this.form);
            this.$set(this, "the_form", f);
            return
        }
        if (typeof this.$route.params.form_slug != 'undefined') {
            this.loadForm(this.$route.params.form_slug)
            return
        }
        if (typeof this.slug != 'undefined' && this.slug != null) {
            this.loadForm(this.slug)
            return
        }
    },
    computed: {
        'is_visible': function() {
            if (this.the_form.EnabledInd == true || this.hasPermission('manage:Forms'))
                return true
            return false
        },
        disable_field: function() {
            if(this.the_form.AgentEditResponse){
                return false
            }


            if(this.the_form.SingleResponse === true && this.the_form.FormResponse.AgentID !== null){
                return true
            }

            return false
        },
        form_agent_code: function() {

            return this.the_form?.FormResponse?.AgentCode ?? this.user.AgentCode
        }
    },
    methods: {
        resetForm: function() {
            this.reload_counter = this.reload_counter + 1;
            this.$router.push({ query: {} })
            //this.loadForm(this.slug)
            this.show_response = false;
            this.reloadForm()
        },
        showResponseText: function() {
            if(this.the_form.FormConfig.response){
                this.show_response = true;
            }
        },
        loadForm: function(slug) {
            var g = this
            g.loaded = false
            g.$set(g, "the_form", null);
            var response_id = this.$route.query.form_response_id ? this.$route.query.form_response_id : 'new';
            QuilityAPI.getFormWithSlug(slug, response_id, ['SingleEntryResponse'], this.form_agent_code)
                .then(function(data) {
                    g.$set(g, "the_form", data);
                    g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        //we just need a blank template.
        reloadForm: function() {
            var g = this
            g.loaded = false
            g.loading = true
            var id = this.the_form.ID
            this.the_form = null;
            QuilityAPI.getForm(id, null, ['SingleEntryResponse'], this.form_agent_code)
                .then(function(data) {
                    g.$set(g, "the_form", data);
                    g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.loading = false
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
    },
    watch: {
        '$route.params.form_slug': function(newV, oldV) {
            if (typeof newV != 'undefined') {
                this.loadForm(newV);
            }
        },
        'slug': function(newV, oldV) {
            if (typeof newV != 'undefined' && newV != null) {
                this.loadForm(newV);
            }
        },
        loaded(val) {
            if (val) {
                if (this.$route.hash) {
                    const element = document.querySelector(this.$route.hash)

                    if (element) {
                        const top = element.getBoundingClientRect().top + window.pageYOffset - 100
                        window.scrollTo({ top })
                    }
                }
            }
        }
    },
    components: {
        Default,
        News,
        Contests,
        Resources,
        Marketing,
        Events,
        Training,
        Benefits,
        Zendesk,
        External,
        Carrier,
        CarrierResources,

        FmlQuestionSet

        /*
        Spacer,
        CallToAction,
        FullWidthText,
        TwoCol5050,
        TwoCol2575,
        TwoCol7525,
        ThreeCol333333,
        ThreeCol255025,
        FourCol25252525,
        CardStyles,
        ButtonGroup,
        CardGroup,
        IframeGroup,
        Banner,
        ZendeskText,
        ScriptEmbedGroup,
        AgencyBuilding,
        CarrierContactInfo,
        CarrierProducts,
        CarrierLogo,
        AcknowledgeCheckBox,
        WistiaVideoGroup,
        Accordion,
        DataTableGroup,
        SummitCalculator,
        QuilityTech
        */
    },
}
</script>
<style scoped>
.fml-edit-btn {
    width: auto !important;
    padding: 0 15px !important;
}

.fml-edit-btn {
    padding-left: 15px;
}
</style>