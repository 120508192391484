<template>
    <div>
        <q-quility-rx-leaderboards v-if="showRxLeaderboard" :board="contentData.leaderboard_type" :title="getTitle" :type="getRxLeaderboardType"></q-quility-rx-leaderboards>
        <div v-else class="text-center pa-12 ma-6">
            <q-leaderboard-data-table :title="getTitle" :rows="10" :leaderboard-data="getData()" :agentPosition="agentPositions.PersonalProducer" :start-date="startDate" :end-date="endDate"></q-leaderboard-data-table>
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
        </div>
    </div>
</template>
<script>
import QLeaderboardDataTable from '@/components/datatables/QLeaderboardDataTable.vue'
import QQuilityRxLeaderboards from '@/components/datatables/QQuilityRxLeaderboards.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "Leaderboard",
    components: {
        QLeaderboardDataTable,
        QQuilityRxLeaderboards
    },
    mounted: function() {
        this.loadData()
    },
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                    background_image: null,
                    background_color: null,
                    text_class: null,
                    leaderboard_type: null,
                    Title: ''
                }
            }
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            leaderboard_data: {
                PersonalProducer: [],
                AgencyDirector: [],
                AgencyOwner: [],
                KeyLeader: []
            },
            agentPositions: {
                PersonalProducer: {},
                AgencyDirector: {},
                AgencyOwner: {},
                KeyLeader: {}
            },
            startDate: null,
            endDate: null,
            loading: false
        }
    },
    computed: {
        showRxLeaderboard: function() {
            if(this.contentData.leaderboard_type.indexOf('Quility Rx') !== false)
                return true
            return false
        },
        getRxLeaderboardType: function() {
            if(this.contentData.leaderboard_type.indexOf('Baseshop') > 0)
                return 'baseshop'
            return 'personal'
        },
        getTitle: function() {
            if(this.contentData.Title !== null && this.contentData.Title !== undefined && this.contentData.Title !== '')
                return this.contentData.Title
            switch(this.contentData.leaderboard_type) {
                case "Personal Producer":
                    return "Top 10 Personal Producers"
                case "Agency Director":
                    return "Top 10 Agency Directors"
                case "Agency Owner":
                    return "Top 10 Agency Owners"
                case "Key Leader":
                    return "Top 10 Key Leaders"
                default:
                    return ''
            }
        }
    },
    methods: {
        loadData: function() {
            if(this.contentData.leaderboard_type.indexOf('Quility Rx') !== false)
                return

            var g = this
            this.loading = true;
            var opt = {
                leadershipLevel: this.contentData.leaderboard_type
            }
            QuilityAPI.loadTop5Leadersboards('monthly', opt).then(function(json) {
                g.$set(g, 'leaderboard_data', json.data)
                g.$set(g, 'agentPositions', json.meta.agent_positions)
                g.$set(g, 'startDate', json.meta.start_date)
                g.$set(g, 'endDate', json.meta.end_date)
                g.loading = false
            }).catch(function() {
                g.loading = false
            })
        },
        getData: function() {
            //'Personal Producer', 'Agency Director', 'AgencyOwner', 'Key Leader'
            switch(this.contentData.leaderboard_type) {
                case "Personal Producer":
                    return this.leaderboard_data.PersonalProducer
                case "Agency Director":
                    return this.leaderboard_data.AgencyDirector
                case "Agency Owner":
                    return this.leaderboard_data.AgencyOwner
                case "Key Leader":
                    return this.leaderboard_data.KeyLeader
            }
        }
    }
}

</script>
