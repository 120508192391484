<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-training-submenu></q-training-submenu>
        <div class="q-sticky-buffer">
            <v-container fluid class="px-5 grey lighten-5 site-width">
                <slot></slot>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import QTrainingSubmenu from '@/components/navigation/Submenus/QTrainingSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QTrainingSubmenu,
    },
}
</script>