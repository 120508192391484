<template>
    <q-form :content-data="contentData">
        <template v-slot:meta>
            <v-col cols="12" sm="4" class="mt-10 pl-5">
                <v-progress-linear indeterminate v-if="data_loading"></v-progress-linear>
                <v-select v-model="value.formSlug" :items="available_forms" label="Select Form"></v-select>
            </v-col>
        </template>
    </q-form>
</template>
<script>
import QForm from '@/CMS/views/ContentBlocks/Form.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    props: ['value'],
    data: function() {
        return {
            the_data: [],
            data_loading: false
        }
    },
    mounted: function() {
        this.loadAvailableForms();
    },
    computed: {
        available_forms: function() {
            var r = [];
            this.the_data.forEach(function(f) {
                r.push({
                    'text': f.FormTitle,
                    'value': f.Slug
                });
            });
            return r;
        },
        contentData: function() {
            return {
                formSlug: this.value.formSlug,
            }
        }
    },
    methods: {
        loadAvailableForms: function() {
            this.data_loading
            var g = this
            QuilityAPI.getForms().then(function(json) {
                g.$set(g, 'the_data', json.data);
                g.data_loading = false;
            }).catch(function(err) {
                g.data_loading = false;
                g.showError(err)
            })
            this.firstPull = false;
        }
    },
    components: {
        QForm
    },
}
</script>