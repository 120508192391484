<template>
    <div class="question_field mx-2">
        <slot name="meta" />
        <strong v-if="!question.FormQuestionConfig.useQuestionAsLabel">{{question.FormQuestionTitle}}</strong>
        <div v-if="question.FormQuestionContent" v-html="question.FormQuestionContent"></div>
        <v-text-field :disabled="disableField" :label="field_label" :rules="question_rules" :hint="question.FormQuestionConfig.hint" :prefix="question.FormQuestionConfig.prefix" :prepend-icon="question.FormQuestionConfig.prependIcon" :suffix="question.FormQuestionConfig.suffix" :append-icon="question.FormQuestionConfig.appendIcon" v-model="value.AnswerContent"></v-text-field>
        <slot></slot>
    </div>
</template>
<script>
import FmlFieldMixins from './FmlFieldMixins.vue'
export default {
    name: "FmlTextField",
    mixins: [FmlFieldMixins],
    data: function() {
        return {
            AnswerDataType: 'String'
        }
    },
}
</script>
