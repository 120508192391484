<template>
    <div class="question_field">
        <slot name="meta">
            <span></span>
        </slot>
        <h5 v-if="!question.FormQuestionConfig.useQuestionAsLabel">{{question.FormQuestionTitle}}</h5>
        <div v-if="question.FormQuestionContent" v-html="question.FormQuestionContent"></div>

        <div class="q-all-rows__wrapper">
            <v-row dense class="q-alt-rows" no-wrap v-for="(answers, aix) in value.AnswerContent" :key="question.ID + '_' + aix">
                <v-col class="d-flex flex-wrap align-center q-alt-rows__row">
                    <div
                        class="q-alt-rows__field"
                        v-for="(field, ix) in question.FormQuestionConfig.fields"
                        :key="field.key + '_' + ix"
                        :is="'fml-' + field.question.FormQuestionConfig.type"
                        v-model="value.AnswerContent[aix]['field_' + field.key]"
                        :question="field.question"
                        v-on:save="$emit('save')"
                        :ref="'question_' + field.key"
                        :disable-field="disableField"
                    />
                </v-col>

                <v-col cols="1" md="1" class="d-flex align-center">
                    <v-btn fab icon color="red" @click="deleteRow(aix)">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <v-btn text @click="addNewRow" depressed color="primary" class="ma-0 pa-0">{{question.FormQuestionConfig.buttonLabel}}
            <v-icon right>fas fa-plus-circle</v-icon>
        </v-btn>
        <slot></slot>
    </div>
</template>
<script>
import FmlTextField from './FmlTextField.vue'
import FmlCheckbox from './FmlCheckbox.vue'
import FmlSelect from './FmlSelect.vue'
import FmlTags from './FmlTags.vue'
import FmlRadio from './FmlRadio.vue'
import FmlSwitch from './FmlSwitch.vue'
import FmlAgentLookup from './FmlAgentLookup.vue'
import FmlLeadLookup from './FmlLeadLookup.vue'


import FmlFieldMixins from './FmlFieldMixins.vue'
export default {
    name: "FmlRepeatable",
    mixins: [FmlFieldMixins],
    data: function() {
        return {
            AnswerDataType: this.question.FormQuestionConfig.AnswerDataType
        }
    },
    mounted: function() {
        this.setUpDefaultValue()
    },
    props: {},
    computed: {

    },
    methods: {
        setUpDefaultValue: function() {
            if (typeof this.value.AnswerContent == "undefined" || this.value.AnswerContent == null) {
                this.$set(this.value, 'AnswerContent', []);
            }
        },
        addNewRow: function() {
            if (typeof this.value.AnswerContent != 'object' || this.value.AnswerContent == null) {
                this.$set(this.value, 'AnswerContent', []);
            }
            var default_row = {}
            this.question.FormQuestionConfig.fields.forEach(function(f) {
                default_row['field_' + f.key] = {
                    FormQuestionID: null,
                    Label: f.question.FormQuestionConfig.label,
                    Title: f.question.FormQuestionTitle,
                    AnswerDataType: f.question.FormQuestionConfig.formatAsCurrency ? 'Currency' : f.question.FormQuestionConfig.AnswerDataType,
                    AnswerContent: f.question.FormQuestionConfig.default
                }
            })
            this.value.AnswerContent.push(default_row);
            //this.$set(this, 'value', [default_row]);

        },
        deleteRow(ix) {
            this.value.AnswerContent.splice(ix, 1);
        },
        updateFieldDefaults: function() {
            this.$set(this.question.FormQuestionConfig, "select_options", {});
            this.$set(this.question.FormQuestionConfig, "AnswerDataType", this.AnswerDataType)
            this.$set(this.question.FormQuestionConfig, "useQuestionAsLabel", true)
            this.$set(this.question.FormQuestionConfig.select_options, "multiple", false)
            this.$set(this.question.FormQuestionConfig.select_options, "item_collection", "Custom")
            this.$set(this.question.FormQuestionConfig.select_options, "label_value", true)
            this.$set(this.question.FormQuestionConfig.select_options, "items_config", "Option A|A\nOption B|B")
            this.$set(this.question.FormQuestionConfig.select_options, "items", [{
                    'text': "Option A",
                    'value': "A"
                },
                {
                    'text': "Option B",
                    'value': "B"
                },
            ])
        },

    },

    components: {
        FmlTextField,
        FmlCheckbox,
        FmlSelect,
        FmlTags,
        FmlRadio,
        FmlSwitch,
        FmlAgentLookup,
        FmlLeadLookup,
    },
    watch: {
        'question.FormQuestionConfig.select_options.multiple': function(newV) {
            if (newV) {
                this.AnswerDataType = 'Array'
            } else {
                this.AnswerDataType = 'String'
            }
            this.question.FormQuestionConfig.AnswerDataType = this.AnswerDataType
        }
    }
}
</script>

<style lang="scss" scoped>
    $strip-background: #0000000c;

    .q-alt-rows {
        &:first-child {
            border: none;
        }

        border-top: 1px solid #ccc;

        &:nth-child(2n) {
            background: $strip-background;
        }

        &__row > * {
            // min-width: 31%;
            // @media only screen and (max-width: 1024px) {
            //     min-width: 50%;
            // }
            // @media only screen and (max-width: 480px) {
            //     min-width: 100%;
            // }
        }
    }
</style>
