<template>
    <v-container fluid class="my-0 px-0  py-0">
        <q-news-submenu></q-news-submenu>
        <div class="q-sticky-buffer">
            <v-container fluid class="px-5">
                <slot></slot>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import QNewsSubmenu from '@/components/navigation/Submenus/QNewsSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QNewsSubmenu
    },
}

</script>
