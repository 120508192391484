<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <v-row class="mx-5">
            <v-col cols="7" sm="6">
                <h2>Documents Library
                    <v-btn icon @click="updateDocumentList">
                        <v-icon>fas fa-redo-alt</v-icon>
                    </v-btn>
                    <v-btn outlined color="primary" @click="showUpload = !showUpload">Upload</v-btn>
                </h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="5" sm="3">
                <q-division-select label="Division*" v-model="Division" :with-all="true"></q-division-select>
            </v-col>
            <v-col cols="5" sm="3">
                <v-select v-model="CategoryName" label="Category" item-text="category" item-value="category" :items="available_categories"></v-select>
            </v-col>
            <v-col cols="12" v-if="showUpload">
                <q-file-upload upload-url="/api/private/agent_document" :params="{'Division' : Division, 'Category' : CategoryName}" :accepted-files="accepted_files" upload-name="document" :max-files="null" v-on:complete="uploadComplete" :text="upload_text"></q-file-upload>
            </v-col>
        </v-row>
        <v-row class="mx-5" dense>
            <v-col cols="6" sm="4">
                <v-text-field v-model="search" label="Search" clearable></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mx-5">
            <v-col cols="12">
                <v-data-table :mobile-breakpoint="2" :search="search" :headers="the_headers" :items="documentList" item-key="ID" class="elevation-0 leaderboard-datatable" :loading="loading" @click:row="previewDocument" :footer-props="{itemsPerPageOptions:[25,50,100]}" :items-per-page.sync="rows" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    <template v-slot:item.ID="{ item }">
                        <span class="nowrap">
                            <v-btn color="q_blue_1" small fab icon @click.stop="downloadDoc(item)">
                                <v-icon>fas fa-download</v-icon>
                            </v-btn>
                            <v-btn color="q_blue_1" small fab icon @click.stop="previewDocument(item)">
                                <v-icon>fas fa-eye</v-icon>
                            </v-btn>
                            <v-btn color="q_resources_1" small fab icon @click.stop="editDoc(item)">
                                <v-icon>fas fa-edit</v-icon>
                            </v-btn>
                            <v-btn small fab icon @click.stop="null" v-clipboard:copy="item.ViewLink" v-clipboard:success="handleCopyStatus">
                                <v-icon>fas fa-copy</v-icon>
                            </v-btn>
                        </span>
                    </template>
                    <template v-slot:item.DocTitle="{ item }">
                        <span>
                            <strong>{{item.DocTitle}}</strong>
                            <v-btn v-if="isPopup" class="ml-5" small @click.stop="useFile(item.ViewLink, 'link')">Link
                                <v-icon right>fas fa-arrow-circle-right</v-icon>
                            </v-btn>
                            <v-btn v-if="isPopup && !justLink" class="ml-5" small @click.stop="useFile(item.ViewLink, 'button-2')">Button
                                <v-icon right>fas fa-arrow-circle-right</v-icon>
                            </v-btn>
                        </span>
                    </template>
					<template v-slot:item.LastChangeDate="{ item }">
						{{ formatDate(item.LastChangeDate) }}
					</template>
                    <template v-slot:item.DocType="{ item }">
                        <span>
                            <v-icon color="q_new_b_1" left>{{ getDocIcon(item.DocType) }}</v-icon>
                            {{item.DocType}}
                        </span>
                    </template>
                    <template v-slot:item.Delete="{ item }">
                        <span v-if="item.DocType != 'delete'">
                            <v-btn color="red" small fab icon @click.stop="deleteDoc(item)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </span>
                        <span v-else>
                            <v-progress-circular indeterminate></v-progress-circular>
                        </span>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12">
                <p><b>HQ Document Security Details</b></p>
                <p>All documents uploaded are "secure" by default. What exactly does this mean? You can share the url with anyone (even someone outside of HQ!), and that url will be available and publicly accessible for 1 hour. After an hour as passed, that url will require a valid HQ sign in to view the document. This way documents in HQ can be shared but will not be crawled by search engines and will not be permanently accessible. If you would like a document to be available publicly, longer than an hour, you can "edit" the document and uncheck the secure checkbox. After doing so the document url will be available publicly and could possibly be crawled by search engines!</p>
            </v-col>
        </v-row>
        <v-dialog v-model="showPDFPreview" width="80%">
            <v-card style="position:relative">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <pdf :src="previewDocumentUrl"></pdf>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDocPreview" width="80%">
            <v-card v-if="previewDocumentDocType == 'image'" style="position:relative">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-img v-if="previewDocumentDocType == 'image'" :src="previewDocumentUrl"></v-img>
            </v-card>
            <v-card v-else style="position:relative">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <VueDocPreview :url="previewDocumentUrl" :type="previewDocumentDocType" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="showEditDoc" width="50%">
            <v-card>
                <v-card-title>Edit Document Properties</v-card-title>
                <v-card-text v-if="showEditDoc">
                    <q-file-upload :upload-url="'/api/private/agent_document/' + currentDoc.ID" :params="{'Division' : currentDoc.Division, 'CategoryName' : currentDoc.CategoryName}" :accepted-files="accepted_files" upload-name="document" :max-files="1" v-on:complete="editUploadComplete" text="Drop new version of document here"></q-file-upload>
                    <v-row wrap>
                        <v-col cols="12">
                            <v-text-field v-model="currentDoc.DocTitle" label="Title"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox v-model="currentDoc.SecureDocumentInd" label="Secure Document"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox v-model="currentDoc.SearchableInd" label="Searchable Document"></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="currentDoc.DocDesc" label="Description"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <q-division-select label="Division*" v-model="currentDoc.Division" :with-all="true"></q-division-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="currentDoc.CategoryName" label="Category" item-text="category" item-value="category" :items="available_categories"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="saveChanges">Save Changes</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import QFileUpload from '@/components/utils/QFileUpload.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import VueDocPreview from 'vue-doc-preview'
import pdf from 'vue-pdf'
import QDivisionSelect from '@/components/utils/QDivisionSelect.vue'
import Category from '@/store/Models/Category'

export default {
    props: ['isPopup', 'justLink'],
    data() {
        return {
            loading: false,
            showUpload: false,
            documentList: [],
            showPDFPreview: false,
            showDocPreview: false,
            previewDocumentDocType: null,
            previewDocumentUrl: null,
            search: null,
            rows: 25,
            showEditDoc: false,
            currentDoc: {},
            Division: 'All',
            CategoryName: null,
            upload_text: "Drop files here to upload.",
			sortBy: 'LastChangeDate',
        	sortDesc: true,
        }
    },
    mounted: function() {
        this.CategoryName = this.available_categories[0].category
        this.updateDocumentList();
    },
    computed: {
        the_headers: function() {
            var headers = [
			{
                text: '',
                value: 'ID',
                width: "200"
            },
			{
                text: 'Title',
                value: 'DocTitle',
            },
			{
                text: 'Updated Date',
                value: 'LastChangeDate',
				sortable: true
            },
			{
                text: 'Type',
                value: 'DocType',
            }]
            if (this.hasPermission('manage:Agent')) {
                headers.push({
                    text: 'Delete',
                    value: 'Delete',
                    width: "100"
                })
            }
            return headers;
        },
        available_categories() {
            var cats = Category.query().where('zendesk_category_id', 0).orderBy('category', 'asc').get();
            cats.unshift({ category: "-All-" });
            return cats;
        },
        accepted_files() {
            return [
                'application/vnd.ms-excel',
                'application/csv',
                'application/vnd.ms-word',
                '.csv',
                '.txt',
                '.jpg',
                '.png',
                '.gif',
                '.docx',
                '.doc',
                '.xlsx',
                '.pdf',
                '.msg',
                '.zip',
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/zip',
                'application/x-compressed',
                'application/x-zip-compressed',
                'multipart/x-zip'
            ].toString()
        }
    },
    methods: {
        uploadComplete: function(resp) {
            this.updateDocumentList()
        },
        editUploadComplete: function(json) {
            var ix = this.indexByKey(this.currentDoc.ID, this.documentList, "ID");
            this.$set(this.documentList, ix, json.data.file);
        },
        updateDocumentList() {
            this.updateUploadText();
            var g = this
            this.loading = true;
            QuilityAPI.getDivisionDocumentList(this.Division, this.CategoryName).then(function(json) {
                g.$set(g, 'documentList', json.data)
                g.loading = false;
            }).catch(function(err) {
                g.showError(err.msg)
                console.log(err)
                g.loading = false;
            })
        },
        previewDocument: function(doc) {
            if (doc.DocType == 'pdf') {
                this.showPDFPreview = true;
                this.showDocPreview = false;
            } else {
                this.showPDFPreview = false;
                this.showDocPreview = true;
                switch (doc.DocType) {
                    case 'doc':
                        this.previewDocumentDocType = "office"
                        break;
                    case 'docx':
                        this.previewDocumentDocType = "office"
                        break;
                    case 'txt':
                        this.previewDocumentDocType = "text"
                        break;
                    case 'csv':
                        this.previewDocumentDocType = "code"
                        break;
                    case 'jpg':
                        this.previewDocumentDocType = "image"
                        break;
                    case 'png':
                        this.previewDocumentDocType = "image"
                        break;
                    case 'gif':
                        this.previewDocumentDocType = "image"
                        break;
                    default:
                        this.previewDocumentDocType = "text"
                        break;
                }
            }
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                this.previewDocumentUrl = doc.DownloadLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL)
                return
            }
            this.previewDocumentUrl = doc.DownloadLink;
        },
        getDocIcon: function(doctype) {
            switch (doctype) {
                case 'csv':
                    return 'fas fa-file-csv'
                case 'doc':
                    return 'fas fa-file-word'
                case 'xls':
                    return 'fas fa-file-excel'
                case 'xlsx':
                    return 'fas fa-file-excel'
                case 'txt':
                    return 'fas fa-file-text'
                case 'pdf':
                    return 'fas fa-file-pdf'
                case 'docx':
                    return 'fas fa-file-word'
                case 'png':
                    return 'fas fa-file-image'
                case 'jpg':
                    return 'fas fa-file-image'
            }
            return 'fas fa-file'
        },
        downloadDoc: function(doc) {
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                window.open(doc.DownloadLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL), "_blank");
                return
            }
            window.open(doc.DownloadLink, "_blank");
        },
        editDoc: function(doc) {
            this.$set(this, 'currentDoc', doc);
            this.showEditDoc = true;
        },
        deleteDoc: function(doc) {
            var g = this
            doc.DocType = "delete"
            QuilityAPI.deleteAgentDocument(doc.ID)
                .then(
                    function(json) {
                        if (json.success === true) {
                            var ix = g.indexByKey(doc.ID, g.documentList, "ID");
                            g.documentList.splice(ix, 1);
                        } else {
                            g.showError("Could not delete this file.")
                        }
                    })
                .catch(
                    function(err) {
                        g.showError(err)
                    })
        },
        saveChanges: function() {
            var g = this
            QuilityAPI.updateDivisionDocument(this.currentDoc)
                .then(
                    function(json) {
                        if (json.success === true) {
                            g.showEditDoc = false;
                            var ix = g.indexByKey(g.currentDoc.ID, g.documentList, "ID");
                            g.$set(g.documentList, ix, json.file);
                            //g.$set(g, 'currentDoc', json.file)
                        } else {
                            g.showError("Could not save changes.")
                        }
                    })
                .catch(
                    function(err) {
                        g.showError(err)
                    })
        },
        closePreview: function() {
            this.previewDocumentUrl = ''
            var g = this
            this.$nextTick(function() {
                g.showPDFPreview = false
                g.showDocPreview = false
            })
        },
        updateUploadText() {
            var d = this.Division == 'All' ? '' : this.Division
            var c = this.CategoryName == '-All-' ? '' : this.CategoryName
            if (d == '' && c == '') {
                this.upload_text = "Drop files here to upload. All divisions will have access. ";
                return
            }
            if (c == '') {
                this.upload_text = "Drop files here to upload into the " + d + " division.";
            } else {
                this.upload_text = "Drop files here to upload into the " + d + " " + c + " category.";
                if (d == '') {
                    this.upload_text = this.upload_text + " All divisions will have access."
                }
            }
        },
        useFile: function(file, style, title) {
            this.$emit('usefile', { 'url': file, 'style': style, 'title': title });
        },
        handleCopyStatus(status) {
            this.showInfo("Document URL copied to clipboard!")
        },
        handleCopyError(status) {
            this.showError("Opps, something went wrong. Select the deisred text and use CTRL+C to copy.")
        },
        viewDoc: function(doc) {
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                window.open(doc.ViewLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL), "_blank")
                return
            }
            window.open(doc.ViewLink, "_blank")
        },
    },
    watch: {
        'Division': function(newV) {
            this.$nextTick(this.updateDocumentList)
        },
        'CategoryName': function(newV) {
            this.$nextTick(this.updateDocumentList)
        }
    },
    components: {
        QFileUpload,
        VueDocPreview,
        pdf,
        QDivisionSelect
    }
}
</script>
