import Category from '@/store/Models/Category'
import Page from '@/store/Models/Page'
import ContestPage from '@/store/Models/ContestPage'
import NewsPage from '@/store/Models/NewsPage'
import SummitNav from '@/store/Models/SummitNav'
import User from '@/store/Models/User'
import PopupNotice from '@/store/Models/PopupNotice'
import { getInstance } from "@/auth/index";
import { EventBus } from '@/event-bus';

export default {

    //payload must have valid access ids for both users.
    /*
    var payload = {
        user_1: {
            access_token: primary_token,
            sub: sub
        },
        user_2: {
            access_token: secondary_tokens.access_token,
            sub: user_2.sub
        }
    }
    */

    getDataInit() {
        return this.getRequest('/api/my/datainit', { attr: 'LeadershipLevel,BaseshopOwnerAgentName,UplineAgentName,SFGDirectAgentName,LastLoginDate,QuilityRXGroupNumber,CurrentSummitBadge,HideDBA,FastTrack,CurrentSummitStep,Flanking,AgentAccount' }).then(
            function(json) {
                Category.create({ data: json.categories })
                //Page.insert({ data: [json.homeslider] })
                NewsPage.insert({ data: json.news })
                ContestPage.insert({ data: json.contests })
                User.commit((state) => {
                    state.auth0 = json.auth
                    state.loading = false
                })
                json.user.nav_menu = json.nav;
                json.user.admin = json.admin
                json.user.all_divisions = json.all_divisions
                json.user.popups_history = json.popups_history
                json.user.enums = json.enums

                //find and set the DivisionID for this user
                json.all_divisions.forEach(division => {
                    if (division.Division == json.user.Agent.Division)
                        json.user.DivisionID = division.ID
                })

                User.create({ data: [json.user] })
                SummitNav.create({ data: json.summit_nav })
                PopupNotice.commit((state) => {
                    state.admin_notices = json.popups
                })
                //impersonation stuff...
                EventBus.$emit("datainit_complete", json.user);
            },
            function(error) {
                console.log(error)
            })
    },
    logActivty(action, label, url, params) {
        this.postRequest('/api/private/user_log', {
            'Action': action,
            'Label': label,
            'URL': url,
            'Params': params
        }).then(
            function(json) {
                return true;
            },
            function(error) {
                console.log(error)
            })
    },

    updateConfig(key, value, field_type, agent_id) {
        var params = {
            "ConfigName": key,
            "ConfigValue": value,
            "DataType": field_type,
            "AgentID": agent_id
        }
        return this.postRequest('/api/my/user_config', params).then(
            function(json) {
                var user = User.query().first()
                user.config = json.data;
                //replaces all existing data with the new user - including the new config.
                User.create({
                    'data': user
                });
            },
            function(error) {
                console.log(error)
            })
    },

    updateAppConfig(key, value, field_type) {
        var params = {
            "ConfigName": key,
            "ConfigValue": value,
            "DataType": field_type
        }
        return this.postRequest('/api/my/app_config', params).then(
            function(json) {
                var user = User.query().first()
                user.config = json.data;
                //replaces all existing data with the new user - including the new config.
                User.create({
                    'data': user
                });
            },
            function(error) {
                console.log(error)
            })
    },

    clearConfig() {
        return this.postRequest('/api/my/config/clear').then(
            function(json) {
                var user = User.query().first()
                user.config = json.data;
                //replaces all existing data with the new user - including the new config.
                User.create({
                    'data': user
                });
            },
            function(error) {
                console.log(error)
            })
    },

    mergeMetadata(payload) {
        return this.postRequest('/api/private/auth0/mergemetadata', payload).then(
            function(json) {
                console.log(json)
            },
            function(error) {
                console.log(error)
            })
    },

    getAccountGrants(agent) {
        return this.getRequest('/api/private/account_grants').then(
            function(json) {
                return json
            },
            function(error) {
                return error
                //console.log(error)
            })
    },

    createAccountGrant(email, name) {
        var params = {
            "name": name,
            "email": email,
            "enabled": true
        }
        return this.postRequest('/api/private/account_grants', params).then(
            function(json) {
                return json
            },
            function(error) {
                return error
                //console.log(error)
            })
    },

    updateAccountGrant(id, email, name, v) {
        var params = {
            "name": name,
            "email": email,
            "enabled": v
        }
        return this.putRequest('/api/private/account_grants/' + id, params).then(
            function(json) {
                return json
            },
            function(error) {
                return error
                //console.log(error)
            })
    },

    getAvailableAccountGrants() {
        return this.getRequest('/api/private/account_grants/available').then(
            function(json) {
                return json
            },
            function(error) {
                return error
                //console.log(error)
            })
    },

    getOptSsoUrl() {
        return this.getRequest('/api/private/opt/sso').then(
            function(json) {
                return json
            },
            function(error) {
                return error
                //console.log(error)
            })
    }
}