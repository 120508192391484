//import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import './component-overrides'
//import "../assets/fontello-2e289b48/css/quility-icons.css";

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        themes: {
            light: {
                primary: '#6ecf93',
                secondary: '#8bd9a9',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                q_dark_gray: '#444',
                lighest_grey: '#f7f7f7',
                q_symm_blue: '#0a3147',
                q_wb_blue: '#76b3d7',
                q_quility_tech: '#4cbdf9',


                /* Blue */
                q_blue_1: '#0083eb',
                q_blue_2: '#339cef',
                q_blue_3: '#66b5f3',
                q_blue_4: '#99cdf7',
                q_blue_5: '#cce6fb',

                /* Forest Green */
                q_resources_1: '#005851',
                q_resources_2: '#337974',
                q_resources_3: '#669b97',
                q_resources_4: '#99bcb9',
                q_resources_5: '#ccdedc',

                /* Grass Green */
                q_agency_1: '#6ecf93',
                q_agency_2: '#8bd9a9',
                q_agency_3: '#a8e2be',
                q_agency_4: '#c5ecd4',
                q_agency_5: '#e2f5e9',

                /* water green */
                q_green_1: '#46c3b2',
                q_green_2: '#6bcfc1',
                q_green_3: '#90dbd1',
                q_green_4: '#b5e7e0',
                q_green_5: '#DAF3F0',

                /* Indigo */
                q_leads_1: '#360568',
                q_leads_2: '#5e3786',
                q_leads_3: '#8669a4',
                q_leads_4: '#af9bc3',
                q_leads_5: '#d7cde1',

                /* Purple */
                q_new_b_1: '#6154a6',
                q_new_b_2: '#8176b8',
                q_new_b_3: '#a098ca',
                q_new_b_4: '#C0BBDB',
                q_new_b_5: '#dfdded',

                /* Plum */
                q_engagement_1: '#7a306c',
                q_engagement_2: '#955989',
                q_engagement_3: '#AF83A7',
                q_engagement_4: '#caacc4',
                q_engagement_5: '#e4d6e2',

                /* Orange */
                q_support_1: '#ff9f1c',
                q_support_2: '#ffb249',
                q_support_3: '#ffc577',
                q_support_4: '#ffd9a4',
                q_support_4: '#ffecd2',

            },
        },
    },
});
