<!--
this component is used in both My Leads and Available Leads
-->
<template>
    <q-submenu base-url="/leads" :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-stycky"></q-submenu>
</template>
<script>
//table data + search
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    props: ["hasDemo"],
    data() {
        return {
            title: "Marketing",
            titlelink: [],
        }
    },
    computed: {
        submenu: function() {
            var g = this;
            var submenu = [{
                label: "Help",
                to: "/support"
            }, ];
            if (this.hasDemo) {
                submenu.push({
                    label: "Start Demo",
                    fun: function() {
                        g.$emit("demo");
                    }
                })
            }
            return submenu;
        },
        buttons: function() {
            var b = [{
                    label: "Video Resources",
                    to: "/page/agent-marketing-video-resources",
                    icon: "fas fa-arrow-alt-circle-right",
                }, {
                    label: "Branding Assets",
                    to: "/page/marketing",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Product Assets",
                    to: "/page/product-concepts",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Marketing Request Form",
                    to: "/page/agent-marketing-request-forms ",
                    icon: "fas fa-arrow-alt-circle-right",
                }
            ];
            /*
            if (this.hasRole("AgencyOwner")) {
                b[1].children.push({
                    label: "Approve Lead Orders",
                    to: "/leads/approve-digital-lead-orders",
                }),
                b[1].children.push({
                    label: "Recruiting Lead Orders",
                    to: "/leads/recruiting-lead-orders",
                })
            }*/
            return b;
        }
    },
    components: {
        QSubmenu
    },
    methods: {

    },
}
</script>
<style>
</style>