import { Model } from '@vuex-orm/core'

export class PopupNotice extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'popup_notice'

    static state() {
        return {
            admin_notices: [],
            loading: false,
            path: '/api/private/popups',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: [],
            availableFilters: null,
            notice: {
                text: '',
                visible: false,
                color: 'error'
            }
        }
    }



    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.uid(''),
            title: this.attr(''),
            content: this.attr(''),
            created_at: this.attr(''),
            link: this.attr([]),
            link_text: this.attr([]),
            audience: this.attr(''),
            AgentCode: this.attr(''),
            AgentName: this.attr(''),
            PCSAgentInd: this.attr(0),
            State: this.attr(''),
            IsSFGDirect: this.attr(0),
            selected_agents: this.attr({}),
            acknowledged_count: this.attr(''),
            audience_count: this.attr(''),
            startDate: this.attr(''),
            endDate: this.attr(''),
            active: this.attr(1),
        }
    }

}

export default PopupNotice;