<template>
    <div>
        <v-row>
            <v-col cols="3">
                <v-combobox label="Leaderboard Type" v-model="value.leaderboard_type" :items="leaderboard_types"></v-combobox>
            </v-col>
            <v-col cols="3">
                <v-text-field label="Leaderboard Title (optional)" v-model="value.Title" />
            </v-col>
            <!-- <v-col cols="3">
                <v-combobox label="Background Color" v-model="value.background_color" :items="available_colors"></v-combobox>
            </v-col>
            <v-col cols="3">
                <v-combobox label="Text Class" v-model="value.text_class" :items="available_classes"></v-combobox>
            </v-col> -->
        </v-row>
    </div>
</template>
<script>
// import Leaderboard from '@/CMS/views/ContentBlocks/Leaderboard.vue'
import QLeaderboardDataTable from '@/components/datatables/QLeaderboardDataTable.vue'
import Styles from '../Styles'
export default {
    props: ['value'],
    data: function() {
        return {
            leaderboard_types: [
                'Personal Producer', 
                'Agency Director', 
                'Agency Owner', 
                'Key Leader', 
                'Quility Rx - Personal Producer Used Codes',
                'Quility Rx - Personal Producer Total Savings',
                'Quility Rx - Personal Producer Compensable',
                'Quility Rx - Baseshop Used Codes',
                'Quility Rx - Baseshop Total Savings',
                'Quility Rx - Baseshop Compensable'
            ],
            available_colors: Styles.Backgrounds,
            available_classes: Styles.Text,
        }
    },
    components: {
        QLeaderboardDataTable
    },
}

</script>
