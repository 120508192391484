import Image from '@/store/Models/Image'
export default {

    getDivisionDocumentList(division, category) {
        if (typeof division == 'undefined' || division == null || division == '') {
            return new Promise((resolve, reject) => { reject() });
        }
        var url = '/api/private/agent_document';
        this.cancelQRequest("divisionDocumentsQ")
        return this.getRequest(url, { 'Division': division, 'CategoryName': category }, 'divisionDocumentsQ').then(function(json) {
            return (json);
        })
    },

    deleteDivisionDocument(id) {
        return this.deleteRequest('/api/private/agent_document' + id).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Document not found"
                } else {
                    msg = err.response.message
                }
                return (msg)
            })
    },

    updateDivisionDocument(doc) {
        return this.putRequest('/api/private/agent_document/' + doc.ID, doc).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Document not found"
                } else {
                    msg = err.response.message
                }
                return (msg)
            })
    },
}
