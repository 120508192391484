<template>
    <div class="d-flex q-business-time-period-select flex-wrap align-center mx-n2 my-n2" :class="alignLeft && 'justify-space-between'">
        <div class="d-flex align-center justify-end" style="flex-grow: 1;">
            <v-switch class="mx-2 my-2" v-if="!hideTimePeriodSwitch && weeksOnly == null || weeksOnly === false" :disabled="disabled" :dark="dark" hide-details inset v-model="monthlyperiod" :label="date_period">
            </v-switch>
        </div>
        <v-select class="mx-2 my-2" style="flex-grow: 1;" :disabled="disabled" :dark="dark" hide-details :label="label" :items="available_weeks" v-model="the_date" :rules="rules" item-text="label" return-object prepend-icon="fas fa-calendar-alt"></v-select>
    </div>
</template>
<script>
// import moment from 'moment'
// import GlobalSessionData from '@/store/Models/GlobalSessionData'

export default {
    name: "QBusinessTimePeriodSelect",
    props: ['value', 'label', 'rules', 'withAll', 'allowCurrent', 'allowNextWeek', 'stateKey', 'dark', 'disabled', 'weeksOnly', 'alignLeft', 'dontReset','earliestDate', 'hideTimePeriodSwitch'],
    data: function() {
        return {
            the_date: this.value,
            monthlyperiod: true,
            stat_period: "months",
        }
    },
    mounted: function() {
        if (this.weeksOnly) {
            this.stat_period = "weeks"
        }
        if (this.dontReset !== false) {
            this.resetSelection()
        }
    },
    computed: {
        date_period: function() {
            return this.stat_period == 'months' ? 'Monthly' : 'Weekly'
        },
        //available_weeks is part of the mixins...
        available_weeks: function() {
            return this.getAvailableBusinessDates(this.stat_period, this.allowNextWeek, this.allowCurrent, this.earliestDate)
        }
    },
    methods: {
        updateValue: function(v, emit) {
            this.the_date = v;
            if (emit === true) {
                this.$emit('input', v);
            }
        },
        resetSelection() {
            this.the_date = this.available_weeks[0];
        },
        selectOption(endDate, timePeriod) {
            var r;
            this.stat_period = timePeriod == 'month' ? "months" : "weeks";
            this.monthlyperiod = timePeriod == 'month';
            this.available_weeks.forEach(function(i) {
                if (i.endDate == endDate && i.period == timePeriod) {
                    r = i
                }
            })
            this.updateValue(r)
        }
    },
    watch: {
        'the_date': function(v) {
            this.updateValue(v, true);
        },
        'value': function(v) {
            this.updateValue(v, false);
        },
        'monthlyperiod': function(v) {
            if (v) {
                this.stat_period = "months"
            } else {
                this.stat_period = 'weeks'
            }
            this.$nextTick(this.resetSelection)
        }
    }
}
</script>

<style lang="scss">
.q-business-time-period-select {
    word-break: normal;
}
</style>