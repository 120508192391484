<template>
    <q-submenu :base-url="baseUrl" :title="title" :titlelink="titlelink" :buttons="buttons" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "Contests",
            titlelink: null,
            baseUrl: '/contests',
            buttons: [{
                    label: "News",
                    to: "/news",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Contests",
                    anchor: "#Contests",
                    icon: "fas fa-arrow-alt-circle-down",
                },
            ],
        }
    },
    computed: {},
    components: {
        QSubmenu
    },
    methods: {},
}

</script>
<style>
</style>
