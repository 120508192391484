export default {
    Backgrounds: [
        "white",
        "black",
        "q_symm_blue",
        "indigo",
        "water-green",
        "grass-green"
    ],
    Text: [
        "light-on-dark",
        "dark-on-light"
    ],
    BlockStyles: [
        "quility_green",
        "symmetry_blue",
        "q-card",
        "elevation-4",
        "light-on-dark",
        "grey-block",
        "white-block",
        "grass-green-block",
        "water-green-block",
        "blue-block",
        "sfg_sky_blue-block",
        "sfg_grey-block",
        "sfg_light_grey-block",
        "sfg_neon_green-block",
        "sfg_yellow-block",
        "sfg_midnight_blue-block",
        "sfg_royal_blue-block",
        "sfg_medium_green-block",
    ],
    CTAStyles: [
        "cta-green",
        "cta-blue"
    ],
    WysiwygStyles: [
        "quote",
        "border-l-white",
        "border-l-gray",
        "with-short-rule",
        "first-with-short-rule"
    ],
    PageStyles: [
        "pure-white",
        "hamsters"
    ],
}
