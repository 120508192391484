import Page from '@/store/Models/Page'
import ContestPage from '@/store/Models/ContestPage'
import NewsPage from '@/store/Models/NewsPage'
import SupportPage from '@/store/Models/SupportPage'
import EventPage from '@/store/Models/EventPage'

const PageTypes = {
    Page,
    ContestPage,
    NewsPage,
    SupportPage,
    EventPage
}

export default {

    getPages(filters, options, model) {
        if (typeof model == 'undefined') {
            model = 'Page'
        }
        PageTypes[model].commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/pages', params).then(function(json) {
            PageTypes[model].create({ data: json.data })
            PageTypes[model].commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
        })
    },
    appendPages(filters, options, model) {
        if (typeof model == 'undefined') {
            model = 'Page'
        }
        PageTypes[model].commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/pages', params).then(function(json) {
            PageTypes[model].insert({ data: json.data })
            PageTypes[model].commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
        })
    },
    getPage(id) {
        let defaultCmsColumnSubBlocks = function(text) {
            if (!text)
                text = "Sed nec diam non eros lobortis tempor et eget sapien. Sed rutrum orci eget ligula mattis, vel vestibulum ligula feugiat. Cras et velit ut ipsum fringilla vulputate."
            var new_id = Math.random()
            return [{
                type: "full-width-text",
                text: text,
                id: new_id,
                type: 'full-width-text',
                hasFocus: false,
                styles: [],
                permissions: { role: [], permission: [], division: [] },
                CarrierID: null,
                CarrierIDs: null,
                key: new_id
            }]
        }

        Page.commit((state) => {
            state.loading = true
            state.currentPage = { blocks: [] }
        })
        if (id == "new") {
            return new Promise((resolve, reject) => {
                Page.commit((state) => {
                    state.loading = false
                    state.currentPage = {
                        title: "The Title",
                        blocks: [{
                            id: Math.floor(Math.random() * 1000000000),
                            type: 'full-width-text',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tristique facilisis malesuada. Aliquam erat volutpat.',
                            hasFocus: false,
                            permissions: {
                                division: [],
                                role: [],
                                permission: []
                            }
                        }, ],
                        template: 'default',
                        category_id: '1',
                        slug: 'the-title',
						division: 'Field',
                        id: null,
                        styles: []
                    }
                    resolve();
                })
            })
        } else {
            var params = {};
            return this.getRequest('/api/private/pages/' + id, params).then(
                function(json) {
                    //adding a seperate key attribute to help with re-rendering in the editVue component
                    json.data.blocks.map(block => {
                        block.key = block.id

                        if (typeof block.ColOne == 'string')
                            block.ColOne = defaultCmsColumnSubBlocks(block.ColOne)

                        if (typeof block.ColTwo == 'string')
                            block.ColTwo = defaultCmsColumnSubBlocks(block.ColTwo)

                        if (typeof block.ColThree == 'string')
                            block.ColThree = defaultCmsColumnSubBlocks(block.ColThree)

                        if (typeof block.ColFour == 'string')
                            block.ColFour = defaultCmsColumnSubBlocks(block.ColFour)

                        //refactor old permissions string format to array
                        if (typeof block.permissions.division == 'string')
                            block.permissions.division = [block.permissions.division]
                        if (typeof block.permissions.permission == 'string')
                            block.permissions.permission = [block.permissions.permission]
                        if (typeof block.permissions.role == 'string')
                            block.permissions.role = [block.permissions.role]

                    })
                    if (typeof json.data.endDate != 'undefined' && json.data.endDate != null) {
                        json.data.endDate = json.data.endDate.substr(0, 10)
                    }
                    Page.commit((state) => {
                        state.loading = false
                        state.currentPage = json.data
                    })
                },
                function(err) {
                    if (err.status == 404) {
                        var msg = "Page not found"
                    } else {
                        msg = err.response.message
                    }
                    Page.commit((state) => {
                        state.loading = false
                        state.notice = {
                            text: msg,
                            visible: true,
                            color: 'error'
                        }
                    })
                })
        }
    },
    getPageWithSlug(slug) {
        Page.commit((state) => {
            state.loading = true
            state.currentPage = null
        })
        var params = {};
        return this.getRequest('/api/private/pages/slug/' + slug, params).then(
            function(json) {
                Page.insert({ data: json.data })
                Page.commit((state) => {
                    state.loading = false
                })
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Page not found"
                } else {
                    msg = err.response.message
                }
                Page.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: msg,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
    createPage(page) {
        Page.commit((state) => {
            state.loading = true
        })
        return this.postRequest('/api/private/pages', page).then(
            function(json) {
                Page.commit((state) => {
                    state.loading = false
                    state.currentPage = json.data
                })
            },
            function(error) {
                Page.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: error.errors,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
    updatePage(page) {
        Page.commit((state) => {
            state.loading = true
        })
        return this.putRequest('/api/private/pages/' + page.id, page).then(
            function(json) {
                Page.commit((state) => {
                    state.loading = false
                    state.currentPage = json.data
                })
            },
            function(error) {
                Page.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: error.errors,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
    deletePage(id) {
        Page.commit((state) => {
            state.loading = true
            state.currentPage = null
        })
        return this.deleteRequest('/api/private/pages/' + id).then(
            function(json) {
                Page.delete(id);
                Page.commit((state) => {
                    state.loading = false
                })
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Page not found"
                } else {
                    msg = err.response.message
                }
                Page.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: msg,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
    getNavMenuItems() {
        return this.getRequest('/api/private/nav').then(
            function(json) {
                return json
            },
            function(error) {

            })
    },
    reorderNavMenuItems(items) {
        return this.postRequest('/api/private/nav/reorder', items).then(
            function(json) {
                return json
            },
            function(error) {
                return error;
            })
    },
    createNavMenuItem(item) {
        return this.postRequest('/api/private/nav', item).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    updateNavMenuItem(item) {
        return this.putRequest('/api/private/nav/' + item.id, item).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    deleteNavMenuItem(id) {
        return this.deleteRequest('/api/private/nav/' + id).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
}