<template>
    <q-submenu :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "Training",
            titlelink: [],
            submenu: [{
                label: "Help",
                to: "/support"
            }]
        }
    },
    computed: {
        'buttons': function() {
            if (this.hasDivision('Corporate') || this.hasRole(['Exec', 'SuperAdmin', 'Staff'])) {
                return [{
                        label: "Quility U",
                        href: "https://quility.lightspeedvt.com/",
                        icon: "fas fa-external-link-alt",

                    },
                    {
                        label: "Summit",
                        to: "/summit",
                        icon: "fas fa-arrow-alt-circle-right",

                    },
                    {
                        label: "Training Scripts",
                        to: "/page/training-scripts",
                        icon: "fas fa-arrow-alt-circle-right",

                    },
                    {
                        label: "Calls & Webinars",
                        to: "/page/corp-calls-and-webinars",
                        icon: "fas fa-arrow-alt-circle-right",

                    },
                    {
                        label: "Telesales Training",
                        to: "/page/telesales",
                        icon: "fas fa-arrow-alt-circle-right",

                    },

                ]
            }
            return [{
                    label: "Quility U",
                    href: "https://auth.quility.com/samlp/z6P8HtF55HXSgXbAT1anWb2BMM2Fdko0",
                    icon: "fas fa-external-link-alt",

                },
                {
                    label: "Summit",
                    to: "/summit",
                    icon: "fas fa-arrow-alt-circle-right",

                },
                {
                    label: "Training Scripts",
                    to: "/page/training-scripts",
                    icon: "fas fa-arrow-alt-circle-right",

                },
                {
                    label: "Calls & Webinars",
                    to: "/page/calls-and-webinars",
                    icon: "fas fa-arrow-alt-circle-right",

                },
                {
                    label: "Telesales Training",
                    to: "/page/telesales",
                    icon: "fas fa-arrow-alt-circle-right",

                },
            ]
        }
    },
    components: {
        QSubmenu
    },
    methods: {},
}
</script>
<style>
</style>