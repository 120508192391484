<template>
    <v-row class="pa-3" justify="space-between" align="start">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <template v-if="time_frame == null">
            <v-col cols="12" sm="12" :md="getWidth(5)" class="text-center" v-if="stat_type == 'baseshop' && showBoard('Quility Rx - Baseshop Used Codes')">
                <q-rx-leaderboard-data-table :title="getTitle('Base Shop Used Codes')" totalTitle="Used Codes" :rows="10" :leaderboard-data="BaseshopLeaderboard"  :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" :md="getWidth(5)" class="text-center" v-if="stat_type == 'personal' && showBoard('Quility Rx - Personal Producer Used Codes')">
                <q-rx-leaderboard-data-table :title="getTitle('Personal Producer Used Codes')" totalTitle="Used Codes" :rows="10" :leaderboard-data="AgentLeaderboard" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" :md="getWidth(5)" class="text-center" v-if="stat_type == 'baseshop' && showBoard('Quility Rx - Baseshop Total Savings')">
                <q-rx-leaderboard-data-table :title="getTitle('Base Shop Total Savings')" :rows="10" :leaderboard-data="BaseshopSavings" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" :md="getWidth(5)" class="text-center" v-if="stat_type == 'personal' && showBoard('Quility Rx - Personal Producer Total Savings')">
                <q-rx-leaderboard-data-table :title="getTitle('Personal Producer Total Savings')" :rows="10" :leaderboard-data="AgentSavings" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col v-if="showFilters" cols="12" sm="12" :md="getWidth(2)">
                <q-business-time-period-select v-model="datePeriod" label="Time Period" :allow-current="true" stateKey="stat" earliestDate="2021-11-25" align-left="true"></q-business-time-period-select>
                <v-radio-group v-model="stat_type">
                    <v-radio label="Personal Producer" value="personal"></v-radio>
                    <v-radio label="Base Shop" value="baseshop"></v-radio>
                </v-radio-group>
            </v-col>

            <v-col cols="12" sm="12" :md="getWidth(5)" class="text-center" v-if="stat_type == 'baseshop' && showBoard('Quility Rx - Baseshop Compensable')">
                <q-rx-leaderboard-data-table :title="getTitle('Base Shop Compensable')" :rows="10" :leaderboard-data="BaseshopCompensable" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" :md="getWidth(5)" class="text-center" v-if="stat_type == 'personal' && showBoard('Quility Rx - Personal Producer Compensable')">
                <q-rx-leaderboard-data-table :title="getTitle('Personal Producer Compensable')" :rows="10" :leaderboard-data="AgentCompensable" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            

        </template>

        <!-- This section is only used on the main dashboard leaderboard section -->
        <template v-else>
            <v-col cols="4" sm="11" md="4" v-if="show_filters">
                <v-radio-group v-model="stat_type" row>
                    <v-radio label="Personal Producer" value="personal"></v-radio>
                    <v-radio label="Base Shop" value="baseshop"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="text-center" v-if="stat_type == 'baseshop'">
                <q-rx-leaderboard-data-table title="Base Shop Used Codes" totalTitle="Used Codes" :rows="10" :leaderboard-data="BaseshopLeaderboard"  :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="text-center" v-if="stat_type == 'personal'">
                <q-rx-leaderboard-data-table title="Personal Producer Used Codes " totalTitle="Used Codes" :rows="10" :leaderboard-data="AgentLeaderboard" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="text-center" v-if="stat_type == 'baseshop'">
                <q-rx-leaderboard-data-table title="Base Shop Total Savings" :rows="10" :leaderboard-data="BaseshopSavings" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="text-center" v-if="stat_type == 'personal'">
                <q-rx-leaderboard-data-table title="Personal Producer Total Savings" :rows="10" :leaderboard-data="AgentSavings" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="text-center" v-if="stat_type == 'baseshop'">
                <q-rx-leaderboard-data-table title="Base Shop Compensable" :rows="10" :leaderboard-data="BaseshopCompensable" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="text-center" v-if="stat_type == 'personal'">
                <q-rx-leaderboard-data-table title="Personal Producer Compensable" :rows="10" :leaderboard-data="AgentCompensable" :start-date="datePeriod.startDate" :end-date="datePeriod.endDate"></q-rx-leaderboard-data-table>
            </v-col>
        </template>
    </v-row>
</template>
<script>
import QRxLeaderboardDataTable from './QRxLeaderboardDataTable.vue'
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: {
        'title': {
            type: String,
            default: null
        },
        'time_frame': {
            type: String,
            default: null
        },
        'board': {
            type: String,
            default: 'all'
        },
        'type': {
            type: String,
            default: 'personal'
        },
        'show_filters': {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            weeklyperiod: false,
            AgentLeaderboard: [],
            BaseshopLeaderboard: [],
            AgentSavings: [],
            BaseshopSavings: [],
            AgentCompensable: [],
            BaseshopCompensable: [],
            startDate: null,
            endDate: null,
            loading: false,
            datePeriod: {
                startDate: null,
                endDate: null,
                period: this.time_frame == 'Weekly' ? 'week' : 'month'
            },
            stat_type: this.type
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        showFilters: function() {
            return ["all", "personal", "baseshop"].includes(this.which_board) || this.show_filters
        }
    },
    methods: {
        getTitle: function(title) {
            if(this.title !== null && this.title != "")
                return this.title
            return title
        },
        showBoard: function(board) {
            return this.board == 'all' || board == this.board
        },
        getWidth: function(cols) {
            if(["all", "personal", "baseshop"].includes(this.which_board) || this.board == 'all')
                return cols
            return 12
        },
        loadData: function() {
            var g = this
            this.loading = true;
            QuilityAPI.loadRxLeadersboards(this.datePeriod).then(function(json) {
                g.datePeriod.startDate = json.meta.start_date
                g.datePeriod.endDate = json.meta.end_date
                
                let rank = 1
                g.AgentLeaderboard  = json.data.AgentLeaderboard.map(item => {
                    return { Rank: rank++, ...item, ...item.agent }
                })
                
                rank = 1
                g.BaseshopLeaderboard = json.data.BaseshopLeaderboard.map(item => {
                    return { Rank: rank++, ...item, ...item.base_shop }
                })
                
                rank = 1
                g.AgentSavings = json.data.AgentSavings.map(item => {
                    return { Rank: rank++, ...item, ...item.agent }
                })
                
                rank = 1
                g.BaseshopSavings = json.data.BaseshopSavings.map(item => {
                    return { Rank: rank++, ...item, ...item.base_shop }
                })

                rank = 1
                g.AgentCompensable = json.data.AgentCompensable.map(item => {
                    return { Rank: rank++, ...item, ...item.agent }
                })
                
                rank = 1
                g.BaseshopCompensable = json.data.BaseshopCompensable.map(item => {
                    return { Rank: rank++, ...item, ...item.base_shop }
                })

                g.loading = false
            }).catch(function() {
                g.loading = false
            })
        }
    },
    components: {
        QRxLeaderboardDataTable,
        QBusinessTimePeriodSelect
    },
    watch: {
        'datePeriod': function(newV) {
            //only refresh if the time_frame prop is null, meaning it's not the board on the main dashboard
            if(this.time_frame == null)
                this.$nextTick(this.loadData)
        },
        'time_frame': function(newV, oldV) {
            this.datePeriod = {
                startDate: null,
                endDate: null,
                period: newV == 'Monthly' ? 'month' : 'week'
            }
            var g = this
            this.$nextTick(function() {
                g.loadData()
            })
        }
    }
}
</script>
