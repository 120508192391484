<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <v-container fluid class="px-5 grey lighten-5">
            <slot></slot>
        </v-container>
    </v-container>
</template>
<script>
//import QResourcesSubmenu from '@/components/navigation/Submenus/QResourcesSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        //QResourcesSubmenu,
    },
}

</script>
