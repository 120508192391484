import Lead from '@/store/Models/Lead'
import NeedsAnalysisLead from '@/store/Models/NeedsAnalysisLead.js'
import { ConvertFields, ConvertAssetFields, RealEstateCurrencyFields, InsuranceCurrencyFields, IncomeCurrencyFields } from '@/components/utils/FormatNeedsAnalysisFields.js'


export default {
    getLeads(agent_code, filters, options) {
        Lead.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("getLeadsQ");
        return this.getRequest('/api/private/leads/agent/' + agent_code, params, "getLeadsQ").then(function(json) {
            if (typeof json.meta == 'undefined') {
                return json;
            }
            Lead.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
            return json;
        })
    },

    getStatusOptions() {
        this.cancelQRequest("LeadStatusOptions");
        return this.getRequest('/api/private/leads/statuses', null, "LeadStatusOptions").then(function(json) {
            return json.data;
        })
    },

    getLeadCounts(agent_code) {
        this.cancelQRequest("LeadCountQ");
        return this.getRequest('/api/private/leads/agent/' + agent_code + "/counts", null, "LeadCountQ").then(function(json) {
            return json.data;
        })
    },

    getLead(lead_code, agentcode, attributes) {
        this.cancelQRequest("getLeadQ");
        Lead.commit((state) => {
            state.currentLead = null
        })
        var params = {};
        if (attributes) {
            var params = {
                attr: attributes.join(",")
            }
        }
        return this.getRequest('/api/private/leads/agent/' + agentcode + "/leads/" + lead_code, params, "getLeadQ").then(function(json) {
            //OptLead.insert({ data: json.data })
            return json.data;
        })
    },

    updateLead(agent_code, lead) {
        var params = {};

        return this.putRequest('/api/private/leads/agent/' + agent_code + '/leads/' + lead.LeadCode, { lead: lead }).then(function(json) {
            //OptLead.insert({ data: json.data })
            return json
        })
    },

    getMyNeedsAnalysisLeads(params) {
        if (typeof this.current_request.abort != 'undefined') {
            this.current_request.abort();
        }
        NeedsAnalysisLead.commit((state) => {
            state.loading = true
        })
        NeedsAnalysisLead.create({ data: [] })
        return this.getRequest('/api/my/needsanalysis/leads/', params).then(function(json) {
            NeedsAnalysisLead.create({ data: json.data })
            NeedsAnalysisLead.commit((state) => {
                state.loading = false
            })
            return json.data
        })
    },

    getMyNeedsAnalysisLead(lead_id) {
        NeedsAnalysisLead.commit((state) => {
            state.loading = true
        })
        var params = {};
        return this.getRequest('/api/my/needsanalysis/leads/' + lead_id, params).then(function(json) {
            NeedsAnalysisLead.insert({ data: json.data })
            NeedsAnalysisLead.commit((state) => {
                state.loading = false
            })
            return json.data
        })
    },

    createMyNeedsAnalysisLead(lead) {
        NeedsAnalysisLead.commit((state) => {
            state.loading = true
        })
        var params = {};

        // ensure that currency fields are numbers and not strings
        ConvertFields(RealEstateCurrencyFields, lead.RealEstate)
        ConvertFields(InsuranceCurrencyFields, lead.LifeInsurance)
        ConvertFields(IncomeCurrencyFields, lead.Income)
        ConvertAssetFields(lead.Assets, lead.Debts)

        return this.postRequest('/api/my/needsanalysis/leads', lead).then(function(json) {
            NeedsAnalysisLead.commit((state) => {
                state.loading = false
            })
            NeedsAnalysisLead.insert({ data: json.data })
            return json.data
        })
    },

    updateMyNeedsAnalysisLead(lead) {
        NeedsAnalysisLead.commit((state) => {
            state.loading = true
        })
        var params = {};

        // ensure that currency fields are numbers and not strings
        ConvertFields(RealEstateCurrencyFields, lead.RealEstate)
        ConvertFields(InsuranceCurrencyFields, lead.LifeInsurance)
        ConvertFields(IncomeCurrencyFields, lead.Income)
        ConvertAssetFields(lead.Assets, lead.Debts)

        return this.putRequest('/api/my/needsanalysis/leads/' + lead.LeadID, lead).then(function(json) {
            NeedsAnalysisLead.commit((state) => {
                state.loading = false
            })
            NeedsAnalysisLead.insert({ data: json.data })
            return json.data
        })
    },

    createReferral(agent_code, lead) {
        this.cancelQRequest("createReferral");
        return this.postRequest('/api/trusted/leads/agent/' + agent_code + '/leads', lead)
            .then(function(json) {
                return json
            })
            .catch(function(err) {
                return err
            })
    },
}