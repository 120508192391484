<template>
    <q-submenu :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "Benefits, Promotions & Bonuses",
            titlelink: [],
            submenu: [{
                label: "Help",
                to: "/support"
            }]
        }
    },
    computed: {
        'buttons': function() {
            return [{
                    label: "Benefits",
                    to: "/page/benefits",
                    icon: "fas fa-arrow-alt-circle-right",

                },
                {
                    label: "Promotion Guidelines",
                    href: "https://hq.quility.com/api/public/document/44375/view/1614050455/819aa43109f79b013f1821d607f96e16",
                    icon: "fas fa-external-link-alt",

                },
                {
                    label: "Bonuses",
                    href: "https://hq.quility.com/api/public/document/44512/view/best-in-class-bonus-program-one-sheet",
                    icon: "fas fa-external-link-alt",

                },
                {
                    label: "Wholesale",
                    to: "/page/quility-wholesale",
                    icon: "fas fa-arrow-alt-circle-right",

                }
            ]
        }
    },
    components: {
        QSubmenu
    },
    methods: {},
}
</script>
<style>
</style>