<template>
    <v-row class="mx-2 my-0" cols="12" width="100%">
        <slot name="meta">
            <div></div>
        </slot>
        <v-col cols="12 text-left">
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <slot name="text">
                <div v-if="carrier">
                    <h3 class="mb-2">{{ carrier.CarrierName }}</h3>
                    <div v-if="carrier.AgentLoginURL" class="px-4 py-1">
                        <a :href="carrier.AgentLoginURL" target="_blank">Agent Login</a>
                    </div>
                    <div v-if="carrier.CarrierURL" class="px-4 py-1">
                        <a :href="carrier.CarrierURL" target="_blank">Website</a>
                    </div>
                    <div v-if="carrier.CarrierEAppURL" class="px-4 py-1">
                        <a text color="primary" :href="carrier.CarrierEAppURL" target="_blank">E-App</a>
                    </div>
                    <div v-if="carrier.AgentPhone" class="px-4 py-1">Phone: <a :href="`tel:${carrier.AgentPhone}`">{{ formatPhoneNumber(carrier.AgentPhone) }}</a></div>
                    <div v-if="carrier.StaffFaxNumber" class="px-4 py-1">Fax: <a :href="`tel:${carrier.StaffFaxNumber}`">{{ carrier.StaffFaxNumber }}</a></div>
                    <div v-if="carrier.HoursOfOperation" class="px-4 py-1">{{ carrier.HoursOfOperation }}</div>
                </div>
                <div v-else-if="!loading">
                    Select carrier
                </div>
            </slot>
        </v-col>
    </v-row>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "CarrierContactInfo",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                    title: null,
                }
            }
        }
    },
    data: () => ({
        carrier: false,
        loading: false
    }),
    mounted: function() {
        this.getCarrier()
    },
    computed: {

    },
    methods: {
        getCarrier: function() {
            if (!this.contentData.CarrierID > 0)
                return

            let g = this
            g.loading = true
            g.carrier = false
            QuilityAPI.getCarrier(this.contentData.CarrierID, true).then(function(json) {
                g.carrier = json.data
                g.loading = false
            })
        }
    },
    watch: {
        'contentData.CarrierID': function(newV, oldV) {
            if (newV > 0)
                this.getCarrier()
            else
                this.carrier = false
        }
    }

}
</script>