<!--
this component is used in both My Leads and Available Leads
-->
<template>
    <q-submenu base-url="/leads" :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-stycky"></q-submenu>
</template>
<script>
//table data + search
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    props: ["hasDemo"],
    data() {
        return {
            title: "Quility Tech",
            titlelink: [],
        }
    },
    computed: {
        submenu: function() {
            var g = this;
            var submenu = [{
                label: "Help",
                to: "/support"
            }, ];
            if (this.hasDemo) {
                submenu.push({
                    label: "Start Demo",
                    fun: function() {
                        g.$emit("demo");
                    }
                })
            }
            return submenu;
        },
        buttons: function() {
            var b = [{
                    label: "HQ How-to",
                    to: "/page/hq-how-to",
                    icon: "fas fa-arrow-alt-circle-right",
                }, {
                    label: "Owner's Circle",
                    to: "/page/owners-circle",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Power Dialer",
                    to: "/page/power-dialer",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Virtual Assistant",
                    to: "/page/virtual-assistant",
                    icon: "fas fa-arrow-alt-circle-right",
                }, ,
                {
                    label: "Virtual Mentor",
                    to: "/page/virtual-mentor",
                    icon: "fas fa-arrow-alt-circle-right",
                }
            ];
            /*
            if (this.hasRole("AgencyOwner")) {
                b[1].children.push({
                    label: "Approve Lead Orders",
                    to: "/leads/approve-digital-lead-orders",
                }),
                b[1].children.push({
                    label: "Recruiting Lead Orders",
                    to: "/leads/recruiting-lead-orders",
                })
            }*/
            return b;
        }
    },
    components: {
        QSubmenu
    },
    methods: {

    },
}
</script>
<style>
</style>