<template>
    <div>
        <Glossary :contentData="value" :sortWords="false">
            <template #header="{ item, index }">
                <div class="q-glossary__header">
                    <div style="width: 100%;">
                        <v-text-field 
                            v-model="item.word"
                            placeholder="Word"
                            @click.stop
                            @keyup.prevent
                        />
                    </div>

                    <v-btn @click="remove(index)" color="error" text icon>
                        <v-icon>fas fa-trash</v-icon>
                    </v-btn> 
                </div>
                
            </template>

            <template #text="{ item }">
                <TextEditor v-model="item.text" placeholder="Description" />
            </template>
        </Glossary>
        <div class="mx-5 my-0">
            <v-btn @click="addNewTerm" style="width: 100%;">
                Add new term
            </v-btn>
        </div>
    </div>
</template>

<script>
import TextEditor from "../TextEditor.vue"
import Glossary from "../../../views/ContentBlocks/Glossary"

const GlossaryKey = 'Glossary'

export default {
    components: { Glossary, TextEditor },

    props: {
        value: { type: Object, default: () => ({}) }
    },

    methods: {
        reset() {
            if (typeof this.value[GlossaryKey] === 'undefined') {
                // Set here. Create reactive field   
                this.$set(this.value, GlossaryKey, [])
                this.addNewTerm()
            }
        },

        addNewTerm() {
            this.value[GlossaryKey].push({
                text: '',
                word: '',
            })
        },

        remove(index) {
            this.value[GlossaryKey].splice(index, 1)
        },
    },

    created() { this.reset() },
}
</script>

<style lang="scss">
.q-glossary__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>