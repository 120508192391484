<template>
    <div>
        <v-autocomplete validate-on-blur v-model="AgentCode" :items="items" :search-input.sync="search_agents" cache-items :item-text="getItemTextLabel" item-value="AgentCode" :label="label" :placeholder="placeholder" :rules="rules" prepend-icon="fas fa-user" :loading="isLoading" auto-select-first clearable :return-object="returnObject"></v-autocomplete>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
export default {
    props: ['value', 'label', 'placeholder', 'returnObject', 'filters', 'rules', 'division', 'addSelf'],
    data: function() {
        return {
            isLoading: false,
            search_agents: null,
            AgentCode: this.value,
            items: [],
            dont_search: false
        }
    },
    mounted: function() {
        if (this.value != null) {
            this.findAgent(this.value)
        }
    },
    methods: {
        updateValue: function(v, emit) {
            this.setDontSearch()
            this.AgentCode = v;
            if (emit === true) {
                this.$emit('input', v);
            }
        },
        getItemTextLabel: function(item) {
            return (item.AgentName + " - " + item.LeadershipLevel + ' (' + item.AgentCode + ')')
        },
        findAgent: function(agentcode) {
            this.isLoading = true
            // Lazily load input items
            var g = this
            QuilityAPI.getAgentStateless(agentcode).then(function(json) {
                g.setDontSearch()
                g.$set(g, 'items', [json])
                g.isLoading = false
            }).catch(function() {
                g.isLoading = false
            });
        },
        //if we just set the agent code for the v-model we don't want to search again for the same agent.
        setDontSearch: function() {
            var g = this
            g.dont_search = true
            setTimeout(function() {
                g.dont_search = false
            }, 1000)
        }
    },
    watch: {
        'AgentCode': function(v) {
            this.updateValue(v, true);
        },
        'value': function(v) {
            this.dont_search = true
            this.findAgent(v)
            this.updateValue(v, false);
        },
        'search_agents': debounce(function(val) {
            //if we just set the agent code for the v-model we don't want to search again for the same agent.
            if (this.dont_search) {
                return
            }
            if (val == null || val.length < 3) {
                this.isLoading = false
                return
            }
            // Items have already been loaded
            //if (this.items.length > 0) return

            // Items have already been requested
            //if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            var g = this
            var options = {
                itemsPerPage: 100,
                search: val,
                page: 1,
                division: this.division,
                ...this.filters
            }
            QuilityAPI.getAgentDownlineSearch(null, options, null, true, function(json) {
                if (g.addSelf) {
                    json.data.push({
                        AgentName: g.user.Agent.AgentName,
                        LeadershipLevel: g.user.Agent.LeadershipLevel,
                        AgentCode: g.user.Agent.AgentCode,
                    })
                }
                g.$set(g, 'items', json.data)
                g.isLoading = false
            });
        }, 250),
    }
}
</script>