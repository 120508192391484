<template>
    <div class="w-full">
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <div v-else-if="question_set" :id="'form-set-' + question_set.ID">
            <h3 v-if="hideTitle !== true" :id="'form-set-' + question_set.ID">{{question_set.FormQuestionSetTitle}}</h3>
            <v-progress-linear v-if="saving" indeterminate></v-progress-linear>
            <v-form lazy-validation v-model="valid" ref="form" :disabled="saving">
                <div class="form-set-field">
                    <v-row wrap v-if="question_set.FormQuestions">
                        <v-col class="q-fml-question_field-wrapper" v-for="(question, idx) in question_set.FormQuestions" :key="getKey(idx, question)" :cols="question.FormQuestionConfig.cols" :sm="question.FormQuestionConfig.smCols" :md="question.FormQuestionConfig.mdCols" :lg="question.FormQuestionConfig.lgCols">
                            <v-badge class="q-fml-auto-save-badge" :value="doNeedAutoSave ? 1 : 0" transition="scale-transition" overlap style="width: 100%" content="Not saved yet">
                                <div
                                    :is="'fml-' + question.FormQuestionConfig.type"
                                    :question="question"
                                    v-model="value.FormResponseAnswers[question.ID]"
                                    :agent-code.sync="AgentCode"
                                    @value-changed="triggerAutoSave"
                                    :disable-field="disableField"
                                />
                            </v-badge>
                        </v-col>
                    </v-row>
                    <slot name="right" />
                </div>

                <v-row v-if="!autoSave">
                    <v-col cols="12" :class="lastSet ? 'text-center' : 'text-right'">
                        <v-btn :disabled="disableField" :x-large="lastSet ? true : false" :color="lastSet ? 'primary' : 'grey lighten-2'" large @click="saveResponse">
                            {{ submit_button_label }}
                            <v-progress-circular size="20" indeterminate right v-if="saving"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </div>
        <div v-else>Whooops! That form is not longer available!</div>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import FmlTextField from './QuestionTypes/FmlTextField.vue'
import FmlTextArea from './QuestionTypes/FmlTextArea.vue'
import FmlCheckbox from './QuestionTypes/FmlCheckbox.vue'
import FmlSelect from './QuestionTypes/FmlSelect.vue'
import FmlTags from './QuestionTypes/FmlTags.vue'
import FmlRadio from './QuestionTypes/FmlRadio.vue'
import FmlSwitch from './QuestionTypes/FmlSwitch.vue'
import FmlAgentLookup from './QuestionTypes/FmlAgentLookup.vue'
import FmlLeadLookup from './QuestionTypes/FmlLeadLookup.vue'
import FmlRepeatable from './QuestionTypes/FmlRepeatable.vue'

/*
This item can be used as a stand alone form for more programmatic implementations.
Listen for these events...
'input' = the response data / question answers was updated.
'submit' = the user clicked the submit button
 */


export default {
    props: {
        'questionSet': {},
        'value': {},
        'slug': {},
        'questionSetIndex': {},
        'lastSet': {},
        'hideTitle': {},
        'appendQueryString': {},
        'redirectOnSave': {},
        formType: { type: String },
        autoSave: { type: Boolean },
        autoSaveDelay: { default: 1000 },
        'disableField': false,
    },
    data: () => ({
        loaded: false,
        loadedQuestionSet: null,
        saving: false,
        valid: false,
        loading: false,
        AgentCode: null,
        doNeedAutoSave: false,
    }),
    mounted: function() {
        //if a question set was not passed then we need to load one
        if (typeof this.questionSet == 'undefined' || this.questionSet == null) {
            var response_id = this.$route.query.form_response_id ? this.$route.query.form_response_id : 'new';
            this.loadQuestionSet(response_id)
            return // we don't want to load the response... it will come with the question set data.
        }
        if (typeof this.value == 'undefined' || this.value == null) {
            this.loadFormResponseTemplate()
        }
    },
    computed: {
        'question_set': function() {
            if (typeof this.questionSet != 'undefined' || this.questionSet != null) {
                return this.questionSet
            }
            if (this.loadedQuestionSet != null) {
                return this.loadedQuestionSet;
            }
            return false
        },
        'submit_button_label': function() {
            if (this.lastSet === true) {
                var label = "Submit";
            } else {
                label = this.formType === 'Quiz' ? 'Proceed' : "Save";
            }
            if (typeof this.question_set.FormQuestionSetConfig == 'undefined') {
                return label;
            }
            return this.question_set.FormQuestionSetConfig.saveButtonLabel ? this.question_set.FormQuestionSetConfig.saveButtonLabel : label
        }
    },
    methods: {
        //using the slug... we will load the questions
        loadQuestionSet: function(response_id) {
            var g = this
            g.loaded = false
            g.loading = true
            g.$set(g, "loadedQuestionSet", null);
            QuilityAPI.getFormWithSlug(this.slug, response_id)
                .then(function(data) {
                    g.loading = false
                    g.$set(g, "loadedQuestionSet", data.FormQuestionSets[g.questionSetIndex]);
                    //g.$set(g, "value", data.FormResponse);
                    g.$emit('input', data.FormResponse)
                    //g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.loading = false
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        //we just need the form response. this requires the form id to be set.
        loadFormResponse: function(response_id) {
            var g = this
            g.loaded = false
            g.loading = true
            var id = response_id == "new" ? null : response_id;
            QuilityAPI.getFormResponse(this.question_set.FormID, id)
                .then(function(data) {
                    g.loading = false
                    //g.$set(g, "value", data);
                    g.$emit('input', data)
                    g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.loading = false
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        //we just need a blank template.
        loadFormResponseTemplate: function() {
            var g = this
            g.loaded = false
            g.loading = true
            QuilityAPI.getFormResponseTemplate(this.question_set.FormID)
                .then(function(data) {
                    g.loading = false
                    //g.$set(g, "value", data);
                    g.$emit('input', data)
                    //g.$forceUpdate();
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.loading = false
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
        getKey: function(ix, question) {
            return ix + "-" + question.key + '-' + question.ID + '-' + this.$route.query.form_response_id
        },
        saveResponse: function() {
            var g = this
            g.saving = true
            if (!this.$refs.form.validate()) {
                this.goToTop();
                this.showError("Please correct invalid fields.");
                this.saving = false;
                return false;
            }
            var response = { ...this.value };
            if (this.lastSet) {
                response.SubmittedInd = true
            }

            QuilityAPI.saveFormResponse(this.question_set.FormID, response)
                .then(function(data) {
                    g.saving = false
                    if (typeof data == 'undefined') {
                        g.showError("Whoops! There was a problem submitting the form!")
                        return
                    }
                    g.$emit('saved', data)

                    if (!g.$props.autoSave) {
                        g.$emit('input', data)
                    }

                    if (response.SubmittedInd) {
                        g.$emit('submitted', data)
                    }
                    if (g.redirectOnSave !== false) {
                        g.$router.replace({
                            query: {
                                "form_response_id": data.ID,
                                ...g.appendQueryString
                            }
                        })
                    }
                    /*if (typeof g.$route.query.form_response_id == "undefined") {
                        g.$router.replace({ query: { "form_response_id": data.ID } })
                    } else {
                        g.$forceUpdate();
                    }*/
                })
                .catch(function(err) {
                    g.saving = false
                    g.showError("Whoops! Can't find that form!")
                });
        },
        //slides the form to the top
        goToTop: function() {
            this.$vuetify.goTo('#form-set-' + this.question_set.ID, {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },

        triggerAutoSave(newV, oldV) {
            if (!this.$props.autoSave) { return }
            if (this.disableField) { return }
            if (newV === null || oldV === null) { return }

            this.doNeedAutoSave = true
            // Debounce call autosave
            clearTimeout(this.autoSaveTimeout)
            this.autoSaveTimeout = setTimeout(() => {
                this.saveResponse();
                this.doNeedAutoSave = false
            }, this.autoSaveDelay);
        }
    },
    watch: {
        '$route.query.form_response_id': function(newV, oldV) {
            //if (typeof newV != 'undefined' && typeof oldV == 'undefined') {
            //return
            //}
            //we want to load a specific response id...
            if (typeof newV != 'undefined') {
                //looks like we never got a slug... so we will pull the response directly with the response id and form id.
                if (typeof this.slug == 'undefined') {
                    this.loadFormResponse(newV);
                } else {
                    //we have a slug... the slug endpoint handles this for us...
                    this.loadQuestionSet(newV);
                }
                return
            }
            if (typeof newV == 'undefined') {
                if (typeof this.slug == 'undefined') {
                    //looks like we never got a slug... so we will pull a blank response template
                    this.loadFormResponseTemplate('new');
                } else {
                    //we have a slug... the slug endpoint handles this for us...
                    this.loadQuestionSet('new');
                }
            }
            return
        },
        'slug': function(newV, oldV) {
            if (typeof newV != 'undefined' && newV != null) {
                this.loadQuestionSet()
            }
        },
        loaded(val) {
            if (val) {
                if (this.$route.hash) {
                    const element = document.querySelector(this.$route.hash)

                    if (element) {
                        const top = element.getBoundingClientRect().top + window.pageYOffset - 100
                        window.scrollTo({ top })
                    }
                }
            }
        }
    },
    components: {
        FmlTextField,
        FmlCheckbox,
        FmlSelect,
        FmlTags,
        FmlRadio,
        FmlSwitch,
        FmlAgentLookup,
        FmlLeadLookup,
        FmlRepeatable,
        FmlTextArea
    },
}

</script>
<style lang="scss" scoped>
.fml-edit-btn {
    width: auto !important;
    padding: 0 15px !important;
}

.fml-edit-btn {
    padding-left: 15px;
}

.w-full {
    width: 100%;
}

.form-set-field {
    display: flex;
    .q-fml-question_field-wrapper {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .form-set-field { flex-direction: column; }
}
</style>

<style lang="scss">
.q-fml-auto-save-badge {
    .v-badge__badge {
        transform: translateX(-100%) translateX(-8px);
    }
}
</style>
