<template>
    <v-toolbar flat class="mb-5 toolbar-fix" :style="{ 'width': $vuetify.breakpoint.smAndDown ? '100%' : 'auto' }">
        <div class="">
            <p class="q-title-bar-title text-no-wrap">{{ title }}</p>
            <p v-if="titlelink.label" v-for="(titlelink, key) in titlelink" :key="key" class="q-title-bar-link"><a @click="goTo(titlelink)" target="_blank"> {{titlelink.label}}</a></p>
        </div>
        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        <v-divider class="ml-5" vertical inset v-if="$vuetify.breakpoint.mdAndUp && buttons.length > 0"></v-divider>
        <div class="d-inline-flex justify-start flex-wrap flex-grow-1" v-if="$vuetify.breakpoint.mdAndUp && buttons.length > 0">
            <span v-for="(button, key) in buttons">
                <v-menu offset-y v-if="button && button.children && button.children.length > 0">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" :key="key" :id="button.id" text class="mr-2 ml-2 d-lg-flex" style="justify-content: flex-start; ">
                            <v-icon left v-if=" !isBase && button.anchor">fas fa-arrow-alt-circle-right</v-icon>
                            <v-icon left v-else>{{ button.icon }}</v-icon>
                            {{ button.label }}
                        </v-btn>
                    </template>
                    <v-list style="min-width:230px;">
                        <div v-for="(sub, key2) in button.children" :key="key2" class="ma-5 pointer d-flex" icon="fas fa-scroll" @click="goTo(sub)">{{ sub.label }}</div>
                    </v-list>
                </v-menu>
                <v-btn v-else-if="button" :key="key" @click="goTo(button)" :id="button.id" text class="mr-2 ml-2 d-lg-flex" style="justify-content: flex-start; ">
                    <v-icon left v-if=" !isBase && button.anchor">fas fa-arrow-alt-circle-right</v-icon>
                    <v-icon left v-else>{{ button.icon }}</v-icon>
                    {{ button.label }}
                </v-btn>
            </span>
        </div>
        <!--
            <v-menu offset-y v-model="show_search_results">
                <template v-slot:activator="{ on }">
                    <v-text-field v-model="search_leads" label="Find Lead" dense single-line hide-details append-icon="mdi-magnify"></v-text-field>
                </template>
                <v-list>
                    <v-list-item v-for="(result, key) in search_results" :key="key" @click="loadLead(result)">
                        {{result.Applicant}} {{result.Address}}
                    </v-list-item>
                </v-list>
            </v-menu>
        -->
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="submenu.length > 0">
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon>fas fa-ellipsis-v</v-icon>
                </v-btn>
            </template>
            <v-list style="min-width:230px;">
                <div v-for="(sub, key) in submenu" :key="key" class="ma-5 pointer d-flex" icon="fas fa-scroll" @click="goTo(sub)">{{ sub.label }}</div>
                <div v-if="$vuetify.breakpoint.smAndDown">
                    <div v-for="(button, key) in buttons" :key="key + 'button'" @click="goTo(button)" :icon="buttons.icon" text class="ma-5 d-flex pointer d-sm-flex d-md-flex">{{ button.label }}</div>
                </div>
            </v-list>
        </v-menu>
    </v-toolbar>
</template>
<script>
//table data + search
import MenuItem from '../MenuItem.vue'
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        titlelink: {
            type: Array,
            default: function() { return [] }
        },
        buttons: {
            type: Array,
            default: function() { return [] }
        },
        icon: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        submenu: {
            type: Array,
            default: function() { return [] }
        },
        to: {
            type: String,
            default: null
        },
        baseUrl: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        options() {
            return {
                duration: 300,
                easing: 'easeInOutCubic',
            }
        },
        isBase: function() {
            return this.$router.currentRoute.path == this.baseUrl;
        }
    },

    methods: {
        goTo(target) {
            if (target.anchor) {
                if (!this.isBase) {
                    this.$router.push(this.baseUrl + target.anchor);
                } else {
                    this.$vuetify.goTo(target.anchor, { easing: 'easeInOutCubic', offset: 80 })
                }
            }
            if (target.to) {
                this.$router.push(target.to)
            }
            if (target.href) {
                window.open(target.href, '_blank');
            }
            if (target.link) {
                window.open(target.href, '_blank');
            }
            if (target.fun) {
                target.fun();
            }
        },
    },
}
</script>
