<template>
    <button-group>
        <template v-slot:meta>
            <v-col cols="12">
                <v-text-field v-model="value.GroupTitle" label="Button Group Title"></v-text-field>
            </v-col>
            <v-col cols="4" sm="1">
                <v-text-field v-model="value.NumColumns" label="Number of Columns"></v-text-field>
            </v-col>
        </template>
        <template v-slot:Buttons>
            <v-container>
                <draggable tag="v-row" v-model="value.Buttons" handle=".handle">
                    <v-col v-for="(button, key) in value.Buttons" :key="button.id" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="text-left" min-width="100%">
                        <v-hover v-slot:default="{ hover }">
                            <v-card>
                                <v-card-text>
                                    <v-text-field label="Button Text" v-model="button.text"></v-text-field>
                                    <url-selector v-model="button.href" label="Button Link"></url-selector>
                                    <v-checkbox class="mt-0" v-model="button.newWindow" label="open in new window" false-value="_self" true-value="_blank"></v-checkbox>
                                    <v-combobox label="Color" v-model="button.color" :items="available_colors"></v-combobox>
                                </v-card-text>
                                <v-btn class="handle" v-if="hover" dark color="gray" small fab absolute top left>
                                    <v-icon small>fas fa-arrows-alt</v-icon>
                                </v-btn>
                                <v-btn @click="deleteButton(key)" v-if="hover" fab small absolute top right color="red">
                                    <v-icon small>fas fa-trash</v-icon>
                                </v-btn>
                            </v-card>
                        </v-hover>
                    </v-col>
                </draggable>
                <v-row>
                    <v-col cols="4" sm="1">
                        <v-btn large color="primary" @click="addButton">Add Button</v-btn>
                    </v-col>
                </v-row>                
            </v-container>
        </template>
    </button-group>
</template>
<script>
import draggable from "vuedraggable";
import TextEditor from '../TextEditor.vue'
import ButtonGroup from '@/CMS/views/ContentBlocks/ButtonGroup.vue'
import UrlSelector from '../UrlSelector.vue'
import Styles from '../Styles'
export default {
    props: ['value'],
    data: function() {
        return {
            available_colors: Styles.Backgrounds,
            counter: Math.floor(Math.random() * 1000000000),
        }
    },
    created: function() {
        this.reset();
    },
    components: {
        TextEditor,
        ButtonGroup,
        UrlSelector,
        draggable
    },
    methods: {
        reset: function() {
            if (typeof this.value.Buttons == 'undefined') {
                this.counter = this.counter + 1
                this.$set(this.value, 'Buttons', [{
                    id: this.counter,
                    text: "Click",
                    href: "https://www.quility.com"
                }])
            }
            for (var i = 0; i < this.value.Buttons.length; i++) {
                this.counter = this.counter + 1
                if (typeof this.value.Buttons[i].id == 'undefined') {
                    this.value.Buttons[i].id = this.counter
                }
            }
            if (typeof this.value.NumColumns == 'undefined') {
                this.$set(this.value, 'NumColumns', 4)
            }
            if (typeof this.value.GroupTitle == 'undefined') {
                this.$set(this.value, 'GroupTitle', "")
            }
            this.$emit('input', this.value)
        },
        addButton: function() {
            this.counter = this.counter + 1
            this.value.Buttons.push({
                id: this.counter,
                text: "Click",
                href: "https://www.quility.com",
                newWindow: "_blank"
            })
        },
        deleteButton: function(idx) {
            var g = this;
            this.$nextTick(function() {
                g.$delete(this.value.Buttons, idx)
            })
        }
    },
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.value.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.value.NumColumns);
        }
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
