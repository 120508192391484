<template>
    <v-container fluid class="my-0 px-0  py-0">
        <q-resources-submenu></q-resources-submenu>
        <div class="q-sticky-buffer">
            <v-container fluid class="px-5 transparent site-width">
                <slot></slot>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import QResourcesSubmenu from '@/components/navigation/Submenus/QResourcesSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QResourcesSubmenu,
    },
}

</script>
