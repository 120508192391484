<!--Full width text column -->
<template>
    <div :class="block_class" @click="maybeOpenLink" v-resize="onResize">
        <slot name="meta">
            <div></div>
        </slot>
        <v-row v-if="!editing" class="banner-image__block" :class="contentData.background_color" align="center" justify="center" :style="get_background_style">
            <v-col :class="contentData.text_class + ' text-center'" cols="12">
                <slot name="text">
                    <div v-html="contentData.text" class="pa-12"></div>
                </slot>
            </v-col>
        </v-row>
        <!-- EDITING VIEW OF BOTH IMAGES -->
        <v-row v-if="editing" class="banner-image__block" :class="contentData.background_color" align="center" justify="center" :style="'background-image: url(' +contentData.background_image+ ');'">
            <v-col :class="contentData.text_class + ' text-center'" cols="12">
                <slot name="text">
                    <div v-html="contentData.text" class="pa-12"></div>
                </slot>
            </v-col>
        </v-row>
        <v-row v-if="editing && contentData.background_image_mobile" class="banner-image__block" :class="contentData.background_color" align="center" justify="center" :style="'background-image: url(' +contentData.background_image_mobile+ ');'">
            <v-col :class="contentData.text_class + ' text-center'" cols="12">
                <slot name="text">
                    <div v-html="contentData.text" class="pa-12"></div>
                </slot>
            </v-col>
        </v-row>
    </div>
</template>
<script>
//use this component like so...
//<text pulls html from the editor">

export default {
    name: "Banner",
    //when setting props
    props: {
        contentData: {
            type: Object,
            default: function() {
                return {
                    text: null,
                    background_image: null,
                    background_color: null,
                    text_class: null
                }
            }
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        use_background_image: ''
    }),
    mounted: function() {
        this.onResize()
    },
    computed: {
        get_background_style: function() {
            return "background-image: url('" + this.use_background_image + "');"
        },
        isClickable: function() {
            if (this.contentData.link != null && this.contentData.link != '') {
                return true
            }
            return false
        },
        block_class: function() {
            if (this.isClickable) {
                return "banner clickable"
            }
            return "banner"
        }
    },
    methods: {
        maybeOpenLink: function() {
            if (this.editing) {
                return
            }
            if (this.contentData.link != null && this.contentData.link != '') {
                if (this.contentData.link.indexOf('http') > -1) {
                    window.open(this.contentData.link, "_blank")
                } else {
                    this.$router.push(this.contentData.link)
                }
            }
        },
        onResize: function() {
            if (window.innerWidth < 960 && this.contentData.background_image_mobile) {
                this.use_background_image = this.contentData.background_image_mobile
            } else {
                this.use_background_image = this.contentData.background_image
            }
        }
    }
}
</script>