<!--
this component is used for all Resources pages
-->
<template>
    <q-submenu :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "Resources",
            titlelink: [{
                label: null,
                link: null,
            }],
            buttons: [{
                    label: "Core Values",
                    to: "/page/symmetry-core-values",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Handbook",
                    to: "/page/handbooks",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Levels of Leadership",
                    to: "/levelsofleadership",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Corporate Leadership",
                    to: "/corporate_staff",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Thrive",
                    to: "/page/thrive",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Con•nect",
                    to: "/page/connect",
                    icon: "fas fa-arrow-alt-circle-right"
                },
                {
                    label: "Agent Spotlight",
                    href: "https://form.asana.com/?k=MsYWKxovp-LV32tnh85dvQ&d=271178874148697",
                    icon: "fas fa-external-link-alt"
                },

            ],
            submenu: [{
                label: "Help",
                to: "/support"
            }]
        }
    },
    computed: {},
    components: {
        QSubmenu
    },
    methods: {},
}
</script>
<style>
</style>