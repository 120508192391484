import { Model } from '@vuex-orm/core'
import LeadOrderLine from "./LeadOrderLine"
import LeadQuantity from './LeadQuantity'
import lead_level_qty_multiplier from '@/store/LeadOrderQtyMultiplier'

export default class LeadOrder extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'lead_order'
    static primaryKey = 'ID'

    static state() {
        return {
            loading: false,
            current_page: null,
            from: null,
            last_page: null,
            path: '/api/private/lead_order',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            currentLeadOrder: {}
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            ID: this.uid(0),
            CreateDate: this.attr(''),
            LastChangeDate: this.attr(''),
            LastChangeBy: this.attr(''),
            AgentCode: this.attr(''),
            AgentStatus: this.attr(''),
            OptID: this.attr(''),
            AgentName: this.attr(''),
            BaseShopOwnerAgentCode: this.attr(''),
            BaseShopOwnerOptID: this.attr(''),
            BaseShopOwnerAgentID: this.attr(''),
            BaseshopOwnerAgentName: this.attr(''),
            OrderNumber: this.attr(''),
            OrderType: this.attr(''),
            OrderStatus: this.attr(''),
            NewOrderStatus: this.attr(''),
            OrderDate: this.attr(''),
            ParentLeadOrderID: this.number(0),
            LeadType: this.attr(''),
            lead_order_lines: this.hasMany(LeadOrderLine, 'LeadOrderID', 'ID'),
            lead_quantities: this.hasMany(LeadQuantity, 'LeadOrderID', 'ID'),
            AgencyOrderInd: this.attr(false),
            Fulfilled: this.attr(false),
            Note: this.attr(''),
            Approved: this.attr(false)
        }
    }

    get QtyRequested() {
        var sum = 0;
        if (this.OrderType == "Standing Order" && this.LeadType == "A Lead") {
            for (var i = 0; i < this.lead_quantities.length; i++) {
                if (this.lead_quantities[i].Status == 'Requested') {
                    sum = sum + this.lead_quantities[i].Quantity;
                }
            }
        } else {
            var valid_statuses = ['Open', 'Complete', 'Needs Approval']
            for (var i = 0; i < this.lead_order_lines.length; i++) {
                if (valid_statuses.indexOf(this.lead_order_lines[i].OrderLineStatus) > -1) {
                    for (var j = 0; j < this.lead_order_lines[i].lead_quantities.length; j++) {
                        sum = sum + this.lead_order_lines[i].lead_quantities[j].Quantity;
                    }
                }
            }
        }
        return sum;
    }

    get QtyFilled() {
        var sum = 0;
        for (var l = 0; l < this.lead_order_lines.length; l++) {
            for (var a = 0; a < this.lead_order_lines[l].lead_allocations.length; a++) {
                for (var q = 0; q < this.lead_order_lines[l].lead_allocations[a].lead_quantities.length; q++) {
                    var qty = this.lead_order_lines[l].lead_allocations[a].lead_quantities[q];
                    if (qty.Status == 'Filled' && this.lead_order_lines[l].lead_allocations[a].AllocationStatus != "Error") {
                        sum = sum + (qty.Quantity * lead_level_qty_multiplier[qty.LeadLevel])
                    }
                }
            }
        }
        return sum
    }

}