export default {


    getCarriers(filters, options) {
        this.cancelQRequest("QCarriersGet");
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/carriers', params).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getPossibleCarrierNames() {
        return this.getRequest('/api/private/possible_carrier_names').then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getCarrier(id, forViewing) {
        appends = '';
        if (typeof forViewing != 'undefined') {
            var appends = '?forViewing=true';
        }
        this.cancelQRequest("QCarriersGet");
        return this.getRequest('/api/private/carriers/' + id + appends).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    createCarrier(carrier) {
        return this.postRequest('/api/private/carriers', carrier).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    updateCarrier(carrier) {
        return this.putRequest('/api/private/carriers/' + carrier.ID, carrier).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    deleteCarrier(id) {
        return this.deleteRequest('/api/private/carriers/' + id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },


    getCarrierWritingNumbers(agent_code) {
        return this.getRequest('/api/private/agents/' + agent_code + '/writing_numbers').then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getCarrierWritingNumbersAgency(agent_code) {
        return this.getRequest('/api/private/agents/' + agent_code + '/agency_writing_numbers').then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    saveCarriertWritingNumber(writing_number) {
        if (writing_number.ID > 0) {
            var func = this.putRequest('/api/private/writing_numbers/' + writing_number.ID, writing_number)
        } else {
            func = this.postRequest('/api/private/writing_numbers', writing_number)
        }
        return func.then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    deleteCarriertWritingNumber(id) {
        return this.deleteRequest('/api/private/writing_numbers/' + id).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    inactivateCarrierWritingNumber(id, agent_code) {
        return this.postRequest('/api/private/writing_numbers_activate/' + agent_code + '/' + id, { active: false }).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    activateCarrierWritingNumber(id, agent_code) {
        return this.postRequest('/api/private/writing_numbers_activate/' + agent_code + '/' + id, { active: true }).then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getActiveCarrierWritingNumbers(agent_code) {
        return this.getRequest('/api/private/agents/' + agent_code + '/writing_numbers_active').then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },

    getActiveAgencyCarrierWritingNumbers(agent_code) {
        return this.getRequest('/api/private/agents/' + agent_code + '/agency_writing_numbers_active').then(function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
}