<template>
    <v-card cols="12" width="100%" class="pa-9 ml-3" style="max-width:550px;">
        <v-row>
            <h3 class="q-title text-center">Summit Lead Purchase Calculator</h3>
        </v-row>
        <v-row class="d-flex">
            <v-select :items="Object.keys(formulaConstants).map( x => parseInt(x) )"
                      v-model="contractLevel" label="Contract Level" class="mr-2" style="max-width:145px;">
            </v-select>
            <v-text-field v-model.number="desiredIncome" type="number" label="What I Want in My Bank Account (Net Income)" class="ml-2"></v-text-field>
        </v-row>
        <v-row class="d-flex">
            <v-col>
                A Leads: {{ aLeadsNeeded }}
            </v-col>
            <v-col>
                R Leads: {{ rLeadsNeeded }}
            </v-col>
            <v-col>
                B Leads: {{ bLeadsNeeded }}
            </v-col>
        </v-row>
        <v-row class="d-flex flex-nowrap">
            <div class="text-right">Issue Paid I Need to Achieve: <br />
                <span class="body-2">(To cover lead cost and create desired income)</span>
            </div>
            <div class="pl-5 align-self-end q-display-2 text-right" style="min-width: 150px;">
                {{ neededSubmit }}
            </div>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "QSummitLeadsCalculator",

    props: {
        CalculatorType: String
    },

    mounted() {
        // set contact level to agent's, use 95 for any level higher
        let agentContractLevel = parseInt(this.user.Agent.ContractLevel);
        if (agentContractLevel > 95) {
            agentContractLevel = 95;
        }
        this.contractLevel = agentContractLevel;
    },

    data() {
        return {
            // indexed by Contract Level, these come from the Activate Calculator v7 sheet
            formulaConstants: {
                95: {
                    aLeadConversionRatio: .2987,
                    aLeadAvgAPV: 944.08,
                    aLeadAvgCost: 57.07,
                    rLeadConversionRatio: 1,
                    rLeadAvgAPV: 1079.87,
                    rLeadAvgCost: 0,
                    bLeadConversionRatio: .0596,
                    bLeadAvgAPV: 1028.80,
                    bLeadAvgCost: 8.25
                },
                90: {
                    aLeadConversionRatio: .2908,
                    aLeadAvgAPV: 1006.71,
                    aLeadAvgCost: 54.07,
                    rLeadConversionRatio: 1,
                    rLeadAvgAPV: 1152.59,
                    rLeadAvgCost: 0,
                    bLeadConversionRatio: .0521,
                    bLeadAvgAPV: 957.11,
                    bLeadAvgCost: 7.82
                },
                85: {
                    aLeadConversionRatio: .2578,
                    aLeadAvgAPV: 1056.45,
                    aLeadAvgCost: 51.07,
                    rLeadConversionRatio: 1,
                    rLeadAvgAPV: 990.92,
                    rLeadAvgCost: 0,
                    bLeadConversionRatio: .0441,
                    bLeadAvgAPV: 929.51,
                    bLeadAvgCost: 7.38
                },
                80: {
                    aLeadConversionRatio: .2614,
                    aLeadAvgAPV: 961.12,
                    aLeadAvgCost: 48.06,
                    rLeadConversionRatio: 1,
                    rLeadAvgAPV: 848.78,
                    rLeadAvgCost: 0,
                    bLeadConversionRatio: .0465,
                    bLeadAvgAPV: 806.57,
                    bLeadAvgCost: 6.95
                },
                75: {
                    aLeadConversionRatio: .2677,
                    aLeadAvgAPV: 737.68,
                    aLeadAvgCost: 45.06,
                    rLeadConversionRatio: 1,
                    rLeadAvgAPV: 1027.86,
                    rLeadAvgCost: 0,
                    bLeadConversionRatio: .0329,
                    bLeadAvgAPV: 1054.59,
                    bLeadAvgCost: 6.51
                },
                70: {
                    aLeadConversionRatio: .2090,
                    aLeadAvgAPV: 940.67,
                    aLeadAvgCost: 42.05,
                    rLeadConversionRatio: 1,
                    rLeadAvgAPV: 763.76,
                    rLeadAvgCost: 0,
                    bLeadConversionRatio: .0564,
                    bLeadAvgAPV: 448.75,
                    bLeadAvgCost: 6.08
                },
            },

            desiredIncome: 5000.00,
            contractLevel: 75,

            aLeadsNeeded: 0,
            rLeadsNeeded: 0,
            bLeadsNeeded: 0,
        }
    },

    computed: {
        neededSubmit: function() {
            const constants = this.formulaConstants[this.contractLevel];
            const advanceRatio = 0.75;
            const placeRatio = 0.65;

            let neededIncome = this.desiredIncome;

            // the last divide by 3 is to split up the lead types equally
            let aLeadsNeeded = neededIncome / constants.aLeadAvgAPV / constants.aLeadConversionRatio / 3;
            let rLeadsNeeded = neededIncome / constants.rLeadAvgAPV / constants.rLeadConversionRatio / 3;
            let bLeadsNeeded = neededIncome / constants.bLeadAvgAPV / constants.bLeadConversionRatio / 3;

            // round for display, rounding in the calculation makes the total not match the spreadsheet
            this.aLeadsNeeded = Math.round(aLeadsNeeded);
            this.rLeadsNeeded = Math.round(rLeadsNeeded);
            this.bLeadsNeeded = Math.round(bLeadsNeeded);

            let neededPremiumPlaced = ( neededIncome + aLeadsNeeded * constants.aLeadAvgCost + bLeadsNeeded * constants.bLeadAvgCost )
                / (this.contractLevel / 100)  / advanceRatio;

            return this.formatCurrency(neededPremiumPlaced);
        }
    },
}
</script>

<style scoped>

</style>
