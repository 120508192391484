<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <div class="q-sticky-buffer">
            <v-container fluid class="px-5 transparent site-width">
                <slot></slot>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QAgencyBuildingSubmenu,
    },
}

</script>
