<template>
    <card-group>
        <template v-slot:meta>
            <v-col cols="12">
                <v-text-field v-model="value.Title" label="Title"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field v-model="value.NumColumns" label="Number of Columns"></v-text-field>
            </v-col>
        </template>
        <template v-slot:Cards>
            <draggable tag="v-row" class="px-4" style="width: 100%;" v-model="value.Cards" handle=".handle">
                <v-col v-for="(card, key) in value.Cards" :key="card.id" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="text-left">
                    <v-hover v-slot:default="{ hover }">
                        <v-card>
                            <v-card-text>
                                <image-selector :aspect-ratio="16/9" v-model="card.image" label="Card Image" :show-image="true"></image-selector>
                                <v-text-field class="mt-5" label="Title" v-model="card.title"></v-text-field>
                                <text-editor label="Text" v-model="card.text"></text-editor>
                                <v-text-field class="mt-7" label="Button Text" v-model="card.buttonText"></v-text-field>
                                <url-selector v-model="card.buttonHref" label="Button Link"></url-selector>
                                <v-checkbox class="mt-0" v-model="card.newWindow" label="open in new window" false-value="_self" true-value="_blank"></v-checkbox>
                                <v-text-field class="mt-5" label="Copy to Clipboard Text" v-model="card.copyText"></v-text-field>
                                <v-text-field label="Content to Copy" v-model="card.copyToClipboard"></v-text-field>
                                <v-select :items="button_alignments" v-model="card.buttonAlignment"></v-select>
                            </v-card-text>
                            <v-btn class="handle" v-if="hover" dark color="gray" small fab absolute top left>
                                <v-icon small>fas fa-arrows-alt</v-icon>
                            </v-btn>
                            <v-btn @click="deleteCard(key)" v-if="hover" fab small absolute top right color="red">
                                <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                        </v-card>
                    </v-hover>
                </v-col>
            </draggable>
            <v-row class="px-4">
                <v-col cols="12">
                    <v-btn large color="primary" @click="addCard">Add Card</v-btn>
                </v-col>
            </v-row>
        </template>
    </card-group>
</template>
<script>
import draggable from "vuedraggable";
import TextEditor from '../TextEditor.vue'
import ImageSelector from '../ImageSelector.vue'
import UrlSelector from '../UrlSelector.vue'
import CardGroup from '@/CMS/views/ContentBlocks/CardGroup.vue'
import Styles from '../Styles'
export default {
    props: ['value'],
    data: function() {
        return {
            available_colors: Styles.Backgrounds,
            counter: Math.floor(Math.random() * 1000000000),
            button_alignments: [{
                    text: "Left",
                    value: "left"
                },
                {
                    text: "Center",
                    value: "center"
                },
                {
                    text: "Right",
                    value: "right"
                }


            ]
        }
    },
    created: function() {
        this.reset();
    },
    components: {
        draggable,
        TextEditor,
        CardGroup,
        UrlSelector,
        ImageSelector
    },
    methods: {
        reset: function() {
            if (typeof this.value.Cards == 'undefined') {
                this.counter = this.counter + 1
                this.$set(this.value, 'Cards', [{
                    id: this.counter,
                    image: "/storage/cms_images/16x9-placeholder_wvC2Ck.jpg",
                    title: "",
                    text: "",
                    buttonText: "",
                    buttonHref: "",
                    newWindow: true,
                    copyText: "",
                    copyToClipboard: "",
                    buttonAlignment: "left"
                }])
            }
            if (typeof this.value.NumColumns == 'undefined') {
                this.$set(this.value, 'NumColumns', 4)
            }
            this.$emit('input', this.value)
        },
        addCard: function() {
            this.counter = this.counter + 1
            this.value.Cards.push({
                id: this.counter,
                image: "/storage/cms_images/16x9-placeholder_wvC2Ck.jpg",
                title: "",
                text: "",
                buttonText: "",
                buttonHref: "",
                newWindow: true,
                copyText: "",
                copyToClipboard: "",
                buttonAlignment: "left"
            })
        },
        deleteCard: function(idx) {
            var g = this;
            this.$nextTick(function() {
                g.$delete(this.value.Cards, idx)
            })
        },
    },
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.value.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.value.NumColumns);
        }
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
