<template>
    <iframe-group>
        <template v-slot:meta>
            <v-row cols="12" class="d-block px-5" style="min-width:100% !important;">
                <v-col cols="12">
                    <v-text-field v-model="value.Title" label="Title"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field v-model="value.NumColumns" label="Number of Columns"></v-text-field>
                </v-col>
            </v-row>
        </template>
        <template v-slot:Iframes>
            <v-container>
                <draggable tag="v-row" style="width: 100%" v-model="value.Iframes" handle=".handle">
                    <v-col v-for="(iframe, key) in value.Iframes" :key="iframe.id" cols="12" :sm="getSmallColumns" :md="getMediumColumns" class="text-left px-5">
                        <v-hover v-slot:default="{ hover }">
                            <v-card>
                                <v-card-text>
                                    <text-editor v-model="iframe.pretext"></text-editor>
                                    <iframe :src="iframe.src"></iframe>
                                    <v-text-field label="Source" v-model="iframe.src"></v-text-field>
                                    <text-editor v-model="iframe.text"></text-editor>
                                </v-card-text>
                                <v-btn class="handle" v-if="hover" dark color="gray" small fab absolute top left>
                                    <v-icon small>fas fa-arrows-alt</v-icon>
                                </v-btn>
                                <v-btn @click="deleteIframe(key)" v-if="hover" fab small absolute top right color="red">
                                    <v-icon small>fas fa-trash</v-icon>
                                </v-btn>
                            </v-card>
                        </v-hover>
                    </v-col>
                </draggable>
                <v-row>
                    <v-col cols="6">
                        <v-btn large color="primary" @click="addIframe">Add Iframe</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </iframe-group>
</template>
<script>
import draggable from "vuedraggable";
import TextEditor from '../TextEditor.vue'
import IframeGroup from '@/CMS/views/ContentBlocks/IframeGroup.vue'
export default {
    props: ['value'],
    data: function() {
        return {
            counter: Math.floor(Math.random() * 1000000000),
        }
    },
    created: function() {
        this.reset();
    },
    components: {
        TextEditor,
        IframeGroup,
        draggable
    },
    methods: {
        reset: function() {
            if (typeof this.value.Iframes == 'undefined') {
                this.counter = this.counter + 1
                this.$set(this.value, 'Iframes', [{
                    id: this.counter,
                    title: "",
                    pretext: "",
                    src: "https://www.quility.com",
                    text: "",
                }])
            }
            if (typeof this.value.NumColumns == 'undefined') {
                this.$set(this.value, 'NumColumns', 4)
            }
            this.$emit('input', this.value)
        },
        addIframe: function() {
            this.counter = this.counter + 1
            this.value.Iframes.push({
                id: this.counter,
                title: "",
                pretext: "<h3>Title</h3>",
                src: "https://www.quility.com",
                text: "<p>Some other optional text.</p>",
            })
        },
        deleteIframe: function(idx) {
            var g = this;
            this.$nextTick(function() {
                g.$delete(this.value.Iframes, idx)
            })
        }
    },
    computed: {
        getSmallColumns: function() {
            return Math.round(24 / this.value.NumColumns);
        },
        getMediumColumns: function() {
            return Math.round(12 / this.value.NumColumns);
        }
    },
    watch: {
        'value.type': function(newV) {
            this.$nextTick(this.reset())
        },
    }
}

</script>
