<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-contests-submenu></q-contests-submenu>
        <div class="q-sticky-buffer">
            <v-container fluid class="px-5 grey lighten-5">
                <slot></slot>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import QContestsSubmenu from '@/components/navigation/Submenus/QContestsSubmenu.vue';
export default {
    props: [],
    data: () => ({}),
    components: {
        QContestsSubmenu
    },
}

</script>
