<template>
    <v-list-item @click.stop="clicked" @mouseover="hover = true" @mouseleave="hover = false">
        <v-list-item-action class="main_menu_item">
            <v-badge dot v-if="badgeContent" :color="badgeColor">
                <v-icon small>{{icon_class}}</v-icon>
            </v-badge>
            <v-icon small v-else>{{icon_class}}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
                <span class="item-title" style="font-weight:600;">
                    <slot></slot>
                    {{title}}
                </span>
                <v-chip dark :color="badgeColor" x-small pill left v-if="badgeContent">{{ badgeContent }}</v-chip>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>
<script>
export default {
    props: ['icon', 'text', 'route', 'click', 'href', 'selected', 'small', 'badgeContent', 'badgeColor', 'path', 'title'],
    data: () => ({
        hover: false
    }),
    computed: {
        icon_class: function() {
            if (this.icon.indexOf("icon-q") == 0) {
                if (this.hover || this.selected) {
                    return this.icon + "-select";
                }
            }
            return this.icon;
        }
    },
    methods: {
        clicked: function() {
            if (typeof this.path == 'string') {
                this.$router.push(this.path).catch(err => { console.log("Whoops! " + err) })
            }
            if (typeof this.href == 'string') {
                window.open(this.href, '_blank');
            }
            if (typeof this.route == 'string') {
                this.$router.push({ name: this.route }).catch(err => { console.log("Whoops! " + err) })
            }
            if (typeof this.click == 'function') {
                this.click();
            }
        }
    },
    watch: {}
}

</script>
<style scoped>
</style>
