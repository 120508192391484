import LeadOrder from '@/store/Models/LeadOrders/LeadOrder'
import LeadOrderLine from '@/store/Models/LeadOrders/LeadOrderLine'
import LeadAllocation from '@/store/Models/LeadOrders/LeadAllocation'
import LeadQuantity from '@/store/Models/LeadOrders/LeadQuantity'


export default {
    getLeadOrders(filters, options) {
        var params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("getLeadOrdersQ");
        return this.getRequest('/api/private/leads/lead_order', params, "getLeadOrdersQ").then(function(json) {
            //OptLead.create({ data: json.data })
            if (typeof json.meta == 'undefined') {
                return json;
            }
            return json;
        })
    },

    getLeadOrder(id, filters) {
        return this.getRequest('/api/private/leads/lead_order/' + id, filters, null).then(function(json) {
            return json;
        })
    },

    getLeadOrderAllocations(id) {
        return this.getRequest('/api/private/leads/lead_order/' + id + "/allocations?attr=Quantities,LeadOrderLine,LeadOrder", null).then(function(json) {
            return json;
        })
    },

    getLeadOrderHistory(id, filters) {
        this.cancelQRequest("getLeadOrderHistoryQ");
        return this.getRequest('/api/private/leads/lead_order/' + id + "/history", filters, "getLeadOrderHistoryQ").then(function(json) {
            return json;
        })
    },

    getLeadOrderLineAudit(filters, options, id) {
        var filters = {
            ...filters,
            ...options
        }
        this.cancelQRequest("getLeadOrderLineAudit");
        return this.getRequest('/api/private/audit/lead_order/' + id + "/order_line_audit_history", filters, "getLeadOrderLineAudit").then(function(json) {
            return json;
        })
    },

    getLeadOrderAudit(filters, options, id) {
        var filters = {
            ...filters,
            ...options
        }
        this.cancelQRequest("getLeadOrderAudit");
        return this.getRequest('/api/private/audit/lead_order/' + id + "/order_audit_history", filters, "getLeadOrderAudit").then(function(json) {
            return json;
        })
    },

    getIAAllocationReport(startDate, endDate, options) {
        var params = {
            ...options,
            ...{
                'startDate': startDate,
                'endDate': endDate
            }
        }
        this.cancelQRequest("getIAAllocationReport");
        return this.getRequest('/api/private/leads/lead_allocation/IA_allocation_report', params, "getIAAllocationReport").then(function(json) {
            return json;
        })
    },

    getAllLeadOrderData(filters, options) {
        var params = {
            ...options,
            ...filters
        }
        params.itemsPerPage = 'all';
        params.OrderType = 'Standing';
        params.LeadType = 'A';
        LeadOrder.commit((state) => {
            state.loading = true
        })
        LeadOrderLine.commit((state) => {
            state.loading = true
        })
        LeadAllocation.commit((state) => {
            state.loading = true
        })
        LeadQuantity.commit((state) => {
            state.loading = true
        })
        this.cancelQRequest("getLeadOrderDataQ");
        this.cancelQRequest("getLeadQuantityDataQ");
        this.cancelQRequest("getLeadOrderLineDataQ");
        this.cancelQRequest("getLeadAllocationDataQ");

        this.getRequest('/api/private/leads/lead_order', params, "getLeadOrderDataQ").then(function(json) {
            LeadOrder.insert({ data: json.data })
        }).then(function() {
            LeadOrder.commit((state) => {
                state.loading = false
            })
        })
        this.getRequest('/api/private/leads/lead_order_line', params, "getLeadOrderLineDataQ").then(function(json) {
            LeadOrderLine.insert({ data: json.data })
        }).then(function() {
            LeadOrderLine.commit((state) => {
                state.loading = false
            })
        })
        this.getRequest('/api/private/leads/lead_allocation', params, "getLeadAllocationDataQ").then(function(json) {
            LeadAllocation.insert({ data: json.data })
        }).then(function() {
            LeadAllocation.commit((state) => {
                state.loading = false
            })
        })
        this.getRequest('/api/private/leads/lead_quantity', params, "getLeadQuantityDataQ").then(function(json) {
            LeadQuantity.insert({ data: json.data })
        }).then(function() {
            LeadQuantity.commit((state) => {
                state.loading = false
            })
        })
    },

    getAgentStandingALeadOrderData(agent_code) {
        var params = {
            //...options,
            //...filters
        }
        params.itemsPerPage = 'all';
        params.OrderType = 'Standing';
        params.LeadType = 'A';
        this.cancelQRequest("getAgentLeadOrderData");
        return this.getRequest('/api/private/leads/agent/' + agent_code + '/lead_order', params, "getAgentLeadOrderData").then(function(json) {
            return json
        })
    },

    getAgencyStandingALeadOrderData(agent_code) {
        var params = {
            //...options,
            //...filters
        }
        params.itemsPerPage = 'all';
        params.OrderType = 'Standing';
        params.LeadType = 'A';
        this.cancelQRequest("getAgencyLeadOrderData");
        return this.getRequest('/api/private/leads/agency/' + agent_code + '/lead_order', params, "getAgentLeadOrderData").then(function(json) {
            return json
        })

    },

    getLastIAAllocationDate(LeadType) {
        this.cancelQRequest("getLastIAAllocationDate");
        return this.getRequest('/api/private/leads/lead_allocation/last_IA_allocation_date', { LeadType: LeadType }, "getLastIAAllocationDate").then(function(json) {
            return json.last_run_date;
        })
    },

    draftIAAllocations(sinceDate, lead_type) {
        this.cancelQRequest("draftIAAllocations");
        return this.postRequest('/api/private/leads/lead_allocation/draft_IA_allocations', { 'sinceDate': sinceDate, 'lead_type': lead_type }, "draftIAAllocations").then(function(json) {
            return json;
        })
    },

    //added the LeadType to this...
    saveIAAllocations(leads, sinceDate, lead_type, pullTime) {
        if (typeof lead_type == 'undefined') {
            lead_type == 'A Lead'
        }
        this.cancelQRequest("saveIAAllocations");
        return this.postRequest('/api/private/leads/lead_allocation/save_IA_allocations', { 'leads': leads, 'sinceDate': sinceDate, 'lead_type': lead_type, 'pullTime': pullTime }, "saveIAAllocations").then(function(json) {
            return json;
        })
    },

    downloadAllocationCSV(filename) {
        return this.getRequest('/api/private/leads/lead_allocation/download_IA_allocation_csv', { 'filename': filename }).then(function(json) {
            return json;
        })
    },

    placeLeadOrder(OrderType, LeadType, agentCode, order_info) {
        this.cancelQRequest("QPlaceLeadOrder");
        var params = {
            'OrderType': OrderType,
            'LeadType': LeadType,
            'AgentCode': agentCode,
            'OrderInfo': order_info
        }
        return this.postRequest('/api/private/leads/lead_orders/' + LeadType + '/' + agentCode, params, 'QPlaceLeadOrder').then(
            function(json) {
                return json;
            },
            function(error) {
                return error;
            })
    },

    getLeadOrderData(agentCode, filters, options) {
        var params = {
            ...options,
            ...filters,
        }
        params.itemsPerPage = 'all';
        LeadOrder.commit((state) => {
            state.loading = true
        })
        LeadOrderLine.commit((state) => {
            state.loading = true
        })
        LeadAllocation.commit((state) => {
            state.loading = true
        })
        LeadQuantity.commit((state) => {
            state.loading = true
        })
        this.cancelQRequest("getLeadOrderDataQ");
        this.cancelQRequest("getLeadQuantityDataQ");
        this.cancelQRequest("getLeadOrderLineDataQ");
        this.cancelQRequest("getLeadAllocationDataQ");

        this.getRequest('/api/private/leads/lead_order', params, "getLeadOrderDataQ").then(function(json) {
            //OptLead.create({ data: json.data })
            LeadOrder.insert({ data: json.data })
        }).then(function() {
            LeadOrder.commit((state) => {
                state.loading = false
            })
        })
        this.getRequest('/api/private/leads/lead_order_line', params, "getLeadOrderLineDataQ").then(function(json) {
            //OptLead.create({ data: json.data })
            LeadOrderLine.insert({ data: json.data })
        }).then(function() {
            LeadOrderLine.commit((state) => {
                state.loading = false
            })
        })
        this.getRequest('/api/private/leads/lead_allocation', params, "getLeadAllocationDataQ").then(function(json) {
            //OptLead.create({ data: json.data })
            LeadAllocation.insert({ data: json.data })
        }).then(function() {
            LeadAllocation.commit((state) => {
                state.loading = false
            })
        })
        this.getRequest('/api/private/leads/lead_quantity', params, "getLeadQuantityDataQ").then(function(json) {
            //OptLead.create({ data: json.data })
            LeadQuantity.insert({ data: json.data })
        }).then(function() {
            LeadQuantity.commit((state) => {
                state.loading = false
            })
        })
    },

    getLeadOrderLinesByStateData(filters, options) {
        this.cancelQRequest("getLeadOrderByStateData");
        var params = {
            ...options,
            ...filters,
        }
        //RETURNS the qty for the lead order lines grouped by state.
        return this.getRequest('/api/private/leads/lead_order_lines_by_state', params, "getLeadOrderByStateData").then(function(json) {
            return json;
        });

    },

    getLeadOrderReceiptSummaryData(filters, options) {
        this.cancelQRequest("getLeadOrderReceiptData");
        var params = {
            ...options,
            ...filters,
        }
        //RETURNS the qty for the lead order lines grouped by state. 
        return this.getRequest('/api/private/leads/lead_order_receipt_summary', params, "getLeadOrderReceiptData").then(function(json) {
            return json;
        });

    },

    getLeadOrderReceiptData(filters, options) {
        this.cancelQRequest("getLeadOrderReceiptData");
        var params = {
            ...options,
            ...filters,
        }
        //RETURNS the qty for the lead order lines grouped by state. 
        return this.getRequest('/api/private/leads/lead_order_receipts', params, "getLeadOrderReceiptData").then(function(json) {
            return json;
        });

    },

    getDownlineLeadOrderLines(filters, options) {
        this.cancelQRequest("getDownlineLeadOrderLines");
        var params = {
            ...options,
            ...filters,
        }
        //RETURNS the qty for the lead order lines grouped by state.
        return this.getRequest('/api/private/leads/approve_downlines', params, "getDownlineLeadOrderLines").then(function(json) {
            return json;
        });

    },

    updateLeadOrder(id, order) {
        if (typeof order.Qty == 'undefined') {
            order.Qty = null;
        }
        var params = {
            'LeadType': order.LeadType,
            'OrderInfo': order,
        }
        return this.putRequest('/api/private/leads/lead_orders/' + id, params).then(function(json) {
            return json;
        })
    },

    approveLeadOrderLine(line) {
        var str = line.Approved ? "approve" : "deny";
        return this.getRequest('/api/private/leads/lead_order_line/' + line.ID + "/" + str).then(function(json) {
            return json;
        })
    },

    saveAllocationQty(allocation) {
        return this.putRequest('/api/private/leads/lead_allocations/log_allocation', allocation).then(function(json) {
            return json;
        })
    },

    getLeadAllocationLog(filters, options) {
        this.cancelQRequest("getLeadAllocationLog");
        var params = {
            ...options,
            ...filters,
        }
        //RETURNS the qty for the lead order lines grouped by state.
        return this.getRequest('/api/private/leads/lead_allocation_log', params, "getLeadAllocationLog").then(function(json) {
            return json;
        });
    },

    getExistingLeadOrderAuctionData(agent_code) {
        this.cancelQRequest("getExistingLeadOrderAuctionData");
        return this.getRequest('/api/private/leads/' + agent_code + '/existing_lead_order_auction/', {}, "getExistingLeadOrderAuctionData").then(function(json) {
            return json;
        });
    },

    getExistingLeadOrderValidationData(agent_code,current_auction_hash) {
        this.cancelQRequest("getExistingLeadOrderValidationData");
        // /private/leads/{agent}/existing_lead_order_auction/validate_hash/{existing_lead_order_hash}
        return this.getRequest('/api/private/leads/' + agent_code + '/existing_lead_order_auction/validate_hash/' + current_auction_hash, {}, "getExistingLeadOrderValidationData").then(function(json) {
            return json;
        });
    }
}