import PopupNotice from '@/store/Models/PopupNotice'

export default {

    getPopupNotices(filters, options) {
        if (typeof this.current_request.abort != 'undefined') {
            this.current_request.abort();
        }
        PopupNotice.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/popups', params).then(function(json) {
            PopupNotice.create({ data: json.data })
            PopupNotice.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
        })
    },

    getPopupNotice(id, agent_code) {
        if (id == 'new') {
            return new Promise((resolve, reject) => {
                resolve({
                    data: {
                        title: null,
                        content: null,
                        link_text: null,
                        link: null,
                        AgentCode: agent_code,
                        audience: null,
                        filters: {},
                        add_acknowledge_btn: null
                    }
                })
            })
        }
        var params = {};
        PopupNotice.commit((state) => {
            state.loading = true
        })
        return this.getRequest('/api/private/popups/' + id, params).then(
            function(json) {
                PopupNotice.insert({ data: json.data })
                PopupNotice.commit((state) => {
                    state.loading = false
                })
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "PopupNotice not found"
                } else {
                    msg = err.response.message
                }
                PopupNotice.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: msg,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
    createPopupNotice(popup) {
        PopupNotice.commit((state) => {
            state.loading = true
        })
        return this.postRequest('/api/private/popups', popup).then(
            function(json) {
                PopupNotice.commit((state) => {
                    state.loading = false
                })
                return json
            },
            function(error) {
                PopupNotice.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: error.errors,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
    acknowledgePopup(id) {
        PopupNotice.commit((state) => {
            state.loading = true
        })
        return this.postRequest('/api/private/popups/' + id + '/acknowledge').then(
            function(json) {
                PopupNotice.commit((state) => {
                    state.loading = false
                })
                return json
            },
            function(error) {
                PopupNotice.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: error.errors,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
    updatePopupNotice(popup) {
        PopupNotice.commit((state) => {
            state.loading = true
        })
        return this.putRequest('/api/private/popups/' + popup.id, popup).then(
            function(json) {
                PopupNotice.commit((state) => {
                    state.loading = false
                })
                return json
            },
            function(error) {
                PopupNotice.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: error.errors,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
    previewPopupNoticeSMS(popup, phone_number) {
        return this.postRequest('/api/private/popups/preview_sms/'+phone_number, popup).then(
            function(json) {
                return json
            },
            function(error) {
                
            })
    },
    deletePopupNotice(id) {
        PopupNotice.commit((state) => {
            state.loading = true
            state.currentPage = null
        })
        return this.deleteRequest('/api/private/popups/' + id).then(
            function(json) {
                PopupNotice.delete(id);
                PopupNotice.commit((state) => {
                    state.loading = false
                })
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Page not found"
                } else {
                    msg = err.response.message
                }
                PopupNotice.commit((state) => {
                    state.loading = false
                    state.notice = {
                        text: msg,
                        visible: true,
                        color: 'error'
                    }
                })
            })
    },
}
