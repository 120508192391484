<template>
    <div>
        <q-submenu :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-sticky"></q-submenu>
    </div>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'

export default {
    data() {
        var g = this
        return {
            title: "Agency Building",
            titlelink: null,
        }
    },
    mounted: function() {
        this.maybeAutoStartDemo('DemoAgency')
    },
    computed: {
        submenu: function() {
            var g = this
            if (this.hasRole(['Staff', 'ContentEditor', 'Exec', 'SuperAdmin'])) {
                return [{
                    label: "Help",
                    to: "/support"
                }, {
                    label: "Weekly Onboarding Report",
                    to: "/admin/reports/onboarding_report"
                }, {
                    label: "Start Demo",
                    fun: function() {
                        g.startDemo();
                    }
                }]
            } else {
                return [{
                    label: "Help",
                    to: "/support"
                }, {
                    label: "Start Demo",
                    fun: function() {
                        g.startDemo();
                    }
                }]
            }
        },
        buttons: function() {
            if (this.hasRole(['SalesRep', 'UnlicensedAgent'])) {
                if (this.hasDivision('Corporate')) {
                    return [{
                        label: "Builders Corner",
                        to: "/page/corp-builders-corner",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-bc"
                    }]
                }
                return [{
                    label: "Builders Corner",
                    to: "/page/builders-corner-new",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-bc"
                }]
            } else if (this.hasPermission(['manage:Agent'])) {
                return [{
                        label: "Agent Directory",
                        to: "/agency-building/directory",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-agent-directory"
                    },
                    {
                        label: "New Agent",
                        to: "/agents/new_agent",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-new-agent"
                    },
                    {
                        label: "Recruiting Stats",
                        to: "/agency-building/recruiting_stats",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-recruiting"
                    },
                    {
                        label: "Agency Structure",
                        to: "/agency-building/structure",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-structure"
                    },
                    {
                        label: "Builders Corner",
                        to: "/page/builders-corner-new",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-bc"
                    },
                ]
            } else {
                var b = [{
                        label: "Agent Directory",
                        to: "/agency-building/directory",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-agent-directory"
                    },
                    {
                        label: "Agency Structure",
                        to: "/agency-building/structure",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-structure"
                    }
                ]
                if (this.hasDivision('Corporate')) {
                    b.push({
                        label: "Builders Corner",
                        to: "/page/corp-builders-corner",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-bc"
                    })
                } else {
                    b.push({
                        label: "Builders Corner",
                        to: "/page/builders-corner-new",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-bc"
                    })
                    b.push({
                        label: "Recruiting Stats",
                        to: "/agency-building/recruiting_stats",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-owner-recruiting"
                    })
                }
                return b;
            }
        }
    },
    components: {
        QSubmenu,
    },
    methods: {
        startDemo() {
            this.$tours['DemoAgency'].start()
        }
    },
}

</script>
<style>
</style>
