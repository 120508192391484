import moment from 'moment'

export default {

    getForms(filters, options) {

        var params = {
            ...options,
            ...filters
        }
        return this.getRequest('/api/private/forms', params).then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    getQuizzes() {
        return this.getRequest('/api/private/quizzes').then(
            function(json) {
                return json
            },
            function(error) {
                return error
            })
    },
    getForm(id, cur_form_type = null, attributes = [], agent_code = null) {
        const formTypes = {
            Quiz: "select",
            simple: "text-field"
        }

        const quiz_configs = {
            select_options: {
                item_collection:"Custom",
                items_config:"Option A|A\nOption B|B",
                label_value:true,
                multiple:false,
                item: [{text:"Option A", value:"A"},{text:"Option B", value:"B"}]
            },
        }

        if (id == "new") {
            return new Promise((resolve, reject) => {
                resolve({
                    CurrentFormType: cur_form_type,
                    FormTitle: "The Title",
                    FormQuestionSets: [{
                        ID: "new",
                        FormQuestionSetTab: "Set 1",
                        FormQuestionSetTitle: "Question Set #1",
                        SortOrder: 1,
                        FormQuestionSetConfig: {
                            styles: [],
                            cols: 12,
                            smCols: null,
                            mdCols: null,
                            lgCols: null,
                        },
                        FormQuestions: [{
                            ID: "new",
                            FormQuestionTitle: "My First Question?",
                            FormQuestionContent: null,
                            SortOrder: 1,
                            FormQuestionConfig: {
                                type: formTypes[cur_form_type] ?? "text-field", // defaulting to text field
                                rules: [],
                                cols: 12,
                                smCols: null,
                                mdCols: null,
                                lgCols: null,
                                label: 'Answer',
                                messages: null,
                                hint: null,
                                prefix: null,
                                prependIcon: null,
                                default: null,
                                permissions: {},
                                useQuestionAsLabel: true,
                                select_options: cur_form_type == "Quiz" ? quiz_configs.select_options : null,
                            },
                        }]
                    }],
                    FormConfig: {
                        template: 'default',
                        form_type: "simple",
                        SingleEntry: false,
                        AgentEditResponse: false,
                    },
                    FormCategory: null,
                    Slug: 'the-title',
                    ID: "new",
                    EffDate: moment().format('YYYY-MM-DD LT'),
                    ExpDate: moment().format('2099-MM-DD LT'),
                    EnabledInd: false,
                    EditableInd: true,
                });
            })
        } else {
            var params = {
                'AgentCode': agent_code
            };

            if(attributes.length)
            {
                params.attr = attributes.join(',')
            }

            return this.getRequest('/api/private/forms/' + id, params).then(
                function(json) {
                    return json.data
                },
                function(err) {
                    if (err.status == 404) {
                        var msg = "Page not found."
                    } else {
                        msg = err.response.message
                    }
                    return {
                        error: true,
                        text: msg,
                        visible: true,
                        color: 'error'
                    }
                })
        }
    },
    getFormWithSlug(slug, response_id, attributes = [], agent_code = null) {
        var params = {
            'FormResponseID': response_id,
            'AgentCode': agent_code,
        };

        if(attributes.length)
        {
            params.attr = attributes.join(',')
        }

        return this.getRequest('/api/private/forms/slug/' + slug, params).then(
            function(json) {
                return json.data
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Page not found."
                } else {
                    msg = err.response.message
                }
                return {
                    error: true,
                    text: msg,
                    visible: true,
                    color: 'error'
                }
            })
    },
    getFormDataWithSlug(slug, filters, opt, agent_code) {
        var params = {
            ...filters,
            ...opt,
            'AgentCode': agent_code
        };
        return this.getRequest('/api/private/forms/slug/' + slug + "/data", params).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Page not found."
                } else {
                    msg = err.response.message
                }
                return {
                    error: true,
                    text: msg,
                    visible: true,
                    color: 'error'
                }
            })
    },
    createForm(form) {
        return this.postRequest('/api/private/forms', form).then(
            function(json) {
                return json.data
            },
            function(error) {
                if (typeof error.msg != undefined) {
                    let msg = error.msg;
                    if (typeof error.errors != undefined) {
                        msg += " " + error.errors;
                    }
                    return {
                        error: true,
                        text: msg,
                        visible: true,
                        color: 'error'
                    }
                }
                if (typeof error.errors != undefined) {
                    return {
                        error: true,
                        text: error.errors,
                        visible: true,
                        color: 'error'
                    }
                }
                return {
                    error: true,
                    text: error,
                    visible: true,
                    color: 'error'
                }
            })
    },
    updateForm(form) {
        return this.putRequest('/api/private/forms/' + form.ID, form).then(
            function(json) {
                return json.data
            },
            function(error) {
                if (typeof error.msg != undefined) {
                    let msg = error.msg;
                    if (typeof error.errors != undefined) {
                        msg += " " + error.errors;
                    }
                    return {
                        error: true,
                        text: msg,
                        visible: true,
                        color: 'error'
                    }
                }
                if (typeof error.errors != undefined) {
                    return {
                        error: true,
                        text: error.errors,
                        visible: true,
                        color: 'error'
                    }
                }
                return {
                    error: true,
                    text: error,
                    visible: true,
                    color: 'error'
                }
            })
    },

    getFormResponse(form_id, response_id) {
        var request = this.getRequest('/api/private/forms/' + form_id + "/response/" + response_id);

        return request.then(
            function(json) {
                return json.data
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Response not found."
                } else {
                    msg = err.response.message
                }
                return {
                    error: true,
                    text: msg,
                    visible: true,
                    color: 'error'
                }
            })
    },
    //gets a blank response tempalte for a form.
    getFormResponseTemplate(form_id) {
        var params = {
            FormResponseID: "new"
        };
        var request = this.getRequest('/api/private/forms/' + form_id, params);

        return request.then(
            function(json) {
                return json.data.FormResponse
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Response not found."
                } else {
                    msg = err.response.message
                }
                return {
                    error: true,
                    text: msg,
                    visible: true,
                    color: 'error'
                }
            })
    },

    getLeadFormResponse(question_id, lead_code, agent_code) {
        var params = {
            FormQuestionID: question_id
        };
        return this.getRequest('/api/private/leads/agent/' + agent_code + "/leads/" + lead_code + "/response", params).then(
            function(json) {
                return json
            },
            function(err) {
                if (err.status == 404) {
                    var msg = "Response not found."
                } else {
                    msg = err.response.message
                }
                return {
                    error: true,
                    text: msg,
                    visible: true,
                    color: 'error'
                }
            })
    },

    saveFormResponse(form_id, response) {
        if (response.ID == "new") {
            var request = this.postRequest('/api/private/forms/' + form_id + "/response", response)
        } else {
            var request = this.putRequest('/api/private/forms/' + form_id + "/response/" + response.ID, response)
        }
        return request.then(
            function(json) {
                return json.data
            },
            function(error) {
                if (typeof error.msg != undefined) {
                    return {
                        error: true,
                        text: error.msg,
                        visible: true,
                        color: 'error'
                    }
                }
                if (typeof error.errors != undefined) {
                    return {
                        error: true,
                        text: error.errors,
                        visible: true,
                        color: 'error'
                    }
                }
                return {
                    error: true,
                    text: error,
                    visible: true,
                    color: 'error'
                }
            })
    },
}
