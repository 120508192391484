<template>
    <vue-dropzone ref="myVueDropzone" v-on:vdropzone-success="uploadComplete" v-on:vdropzone-file-added="fileAdded" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true>
        <div class="dropzone-custom-content">
            <h4 class="dropzone-custom-title">{{text}}</h4>
            <p>...or click to select a file from your computer</p>
        </div>
    </vue-dropzone>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { getInstance } from "@/auth/index";
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: ['uploadUrl', 'acceptedFiles', 'uploadName', 'maxFiles', 'params', 'addRemoveLinks', 'text', 'method'],
    data() {
        return {
            dropzoneOptions: {
                url: this.uploadUrl,
                headers: {
                    'Authorization': null,
                    'Accept': 'application/json',
                },
                method: this.method ? this.method : 'post',
                acceptedFiles: this.acceptedFiles ? this.acceptedFiles : 'image/*',
                addRemoveLinks: this.addRemoveLinks ? this.addRemoveLinks : false,
                'paramName': this.uploadName,
                maxFiles: this.maxFiles,
                params: this.params,
                maxFilesize: 750000000,
                timeout: 180000,
                thumbnailWidth: 75,
                thumbnailHeight: 75,
                dictDefaultMessage: this.text ? this.text : "Drop files here to upload"
            },
            token: null,
        }
    },
    mounted: function() {
        var g = this
        this.config().then(function(token) {
            g.token = token
            g.dropzoneOptions.headers.Authorization = `Bearer ${token}`;
        })
        if (this.text == null) {
            this.text = "Drop files here to upload."
        }
    },
    methods: {
        'uploadComplete': function(file, response) {
            this.hasUpload = true;
            this.$emit('complete', { 'data': response, 'file': file });
        },
        'fileAdded': function(file) {
            this.$emit('fileadded', file);
        },
        'config': function() {
            return new Promise((resolve, reject) => {
                getInstance().getTokenSilently().then(function(token) {
                    resolve(token);
                });
            })
        },
        removeFile: function(file) {
            this.$refs.myVueDropzone.removeFile(file);
        }
    },
    watch: {
        'text': function(newV) {
            console.log(newV)
            this.dropzoneOptions.dictDefaultMessage = newV
            this.$refs.myVueDropzone.setOption('dictDefaultMessage', newV)
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
    }
}
</script>