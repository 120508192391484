<template>
    <v-container>
        <v-row justify="center" v-if="!hideButton">
            <v-col cols="12" class="text-center">
                <v-btn @click="show = !show">Filters</v-btn>
            </v-col>
        </v-row>
        <v-row dense v-if="show && availableFilters" justify="start">
            <v-col v-for="(filter, key) in filtersWithSelect" :key="key" cols="12" xs="6" sm="3" md="2">
                <v-switch v-if="key.indexOf('_switch') > -1" :label="formatLabel(key)" v-model="filters[key]"></v-switch>

                <v-select v-else :label="formatLabel(key)" :items="options[key]" v-model="filters[key]" :multiple="typeof filters[key] == 'object'" :clearable="typeof filters[key] == 'object'"></v-select>
            </v-col>

            <v-col v-if="filtersWithCheckbox && Object.keys(filtersWithCheckbox).length > 0" cols="12" xs="6" sm="3" md="2" class="d-flex justify-center">
                <v-menu :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mt-4 text-wrap"
                            :color="Object.values(filtersWithCheckbox).some((i) => i !== '-All-') ? 'primary' : 'rgba(0, 0, 0, 0.4)'"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            block
                            style="width: 100%;"
                        >
                            Other filters
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(value, key)  in filtersWithCheckbox" :key="key">
                            <QIndeterminateCheckbox 
                                v-model="filters[key]" 
                                :label="`${formatLabel(key)}`" 
                                :nullValue="options[key][0]"
                                :trueValue="options[key][1]"
                                :falseValue="options[key][2]"
                            />
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <slot></slot>
        </v-row>
    </v-container>
</template>
<script>
import QIndeterminateCheckbox from '../controls/QIndeterminateCheckbox.vue'

export default {
    name: "QFilters",
    components: { QIndeterminateCheckbox },
    props: {
        // ['keys', 'data', 'value', 'hideButton', 'availableFilters', ],
        keys: {},
        data: {},
        value: {},
        hideButton: {},
        availableFilters: {},
        /** Filter names that should be hidden in dropdown */
        checkboxesFilters: { type: Array, default: () => [] }
    },
    data: function() { // data internal to component (not available outside)
        return {
            show: false,
            filters: {},
            showFilters: false,
            defaultFilters: {},
        }
    },
    mounted: function() {
        this.defaultFilters = this.value;
        this.resetFilters()
    },
    computed: {
        options: function() {
            if (this.availableFilters) {
                return this.availableFilters;
            }
            var r = {};
            for (var i = 0; i < this.keys.length; i++) {
                r[this.keys[i]] = this.getOptions(this.keys[i]);
            }
            return r;
        },
        filtersWithSelect() {
            const copy = {}

            Object
                .keys(this.filters)
                .filter((key) => !this.checkboxesFilters.includes(key))
                .forEach((key) => { copy[key] = this.filters[key]; })

            return copy
        },
        filtersWithCheckbox() {
            const copy = {}

            Object
                .keys(this.filters)
                .filter((key) => this.checkboxesFilters.includes(key))
                .forEach((key) => { copy[key] = this.filters[key]; })

            return copy
        },
    },
    methods: {
        updateFilters: function() {
            this.$emit('input', this.filters)
        },
        getOptions: function(key) {
            if (this.data) {
                var opt = ['-All-']
                for (var i = 0; i < this.data.length; i++) {
                    if (opt.indexOf(this.data[i][key]) == -1) {
                        opt.push(this.data[i][key]);
                    }
                }
                return opt.sort();
            }
            return [];
        },
        resetFilters: function() {
            for (var i = 0; i < this.keys.length; i++) {
                if (this.defaultFilters[this.keys[i]]) {
                    this.$set(this.filters, this.keys[i], this.defaultFilters[this.keys[i]]);
                } else {
                    this.$set(this.filters, this.keys[i], '-All-');
                }
                if (this.keys[i] == 'Carrier' && this.filterByCarrierID > 0)
                    this.$set(this.filters, this.keys[i], this.filterByCarrierID);
            }
        },
        formatLabel: function(key) {
            return key.replace("_id", "")
                .replace("_switch", "")
                .replace("_", " ")
                .replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
                .replace(' Ind', '')
        },
    },
    watch: {
        'show': function(v) {
            if (!v) {
                this.resetFilters();
            }
        },
        'filters': function(f) {
            this.$emit('input', f)
        },
        $route(to, from) {
            if (to.params && to.params.carrier_id > 0) {
                this.defaultFilters.Carrier = to.params.carrier_id
                this.filterByCarrierID = to.params.carrier_id
                this.resetFilters()
            }
        }
    },
}
</script>
